@import "mixins";
@import "variables";

.container {
    width: 100%;
    height: 224px;
    margin: 40px 0 0 0;

    @media (max-width: $mobile-size) {
        height: 198px;
    }

    .containerwrap {
        display: flex;
        flex-direction: row;

        .logofield {
            width: 180px;
            height: 224px;
            margin: 0 30px 0 0;
            position: relative;

            @media (max-width: $mobile-size) {
                margin: 0 0 0 0;
                width: 90px;
                height: 112px;
            }

            .logofield_ico {
                width: 180px;
                height: 224px;
                background-size: cover;
                background-image: url("../../../../../../resources/logod-arts.svg");

                &.earlybird {
                    background-image: url("../../../../../../resources/logod-arts.png");
                }

                @media (max-width: $mobile-size) {
                    width: 90px;
                    height: 112px;
                }
            }

            .logoclosed {
                position: absolute;
                top: 73px;
                left: 20px;
                width: 140px;
                height: 35px;
                background-color: rgba(#000, 0.8);
                display: flex;
                flex-direction: row;
                justify-content: center;
                @include font(24px, rgba(255, 255, 255, 0.64));
                line-height: 35px;

                @media (max-width: $mobile-size) {
                    width: 80px;
                    height: 20px;
                    font-size: 14px;
                    top: 40px;
                    left: 5px;
                    line-height: 20px;
                }
            }
        }

        .contentsfield {
            width: 590px;
            height: 224px;
            display: flex;
            flex-direction: column;

            @media (max-width: $mobile-size) {
                width: 100%;
                height: 112px;
                margin: 0 0 0 20px;
            }

            .titles {
                margin: 40px 0 0 0;
                width: 100%;
                height: 83px;
                display: flex;
                flex-direction: column;

                @media (max-width: $mobile-size) {
                    margin: 12px 0 0 0;
                    height: 94px;
                }

                .title {
                    height: 29px;
                    line-height: 29px;
                    display: flex;
                    flex-direction: row;
                    @include font(24px, #fff);

                    &.earlybird {
                        @include font(24px, #f29077);
                    }

                    .earlybird_title {
                        margin: -1px 6px 0 0;
                    }

                    @media (max-width: $mobile-size) {
                        height: 42px;
                        line-height: normal;
                        @include font(18px, #fff);

                        &.earlybird {
                            @include font(18px, #f29077);
                            line-height: 20px;
                        }
                    }
                }

                .symbol {
                    height: 20px;
                    margin: 10px 0 0 0;
                    line-height: 20px;
                }

                .name {
                    height: 20px;
                    margin: 4px 0 0 0;
                    line-height: 20px;
                }
            }

            .distribute {
                margin: 10px 0 0 0;
                width: 100%;
                height: 52px;
                display: flex;
                flex-direction: column;

                @media (max-width: $mobile-size) {
                    display: none;
                }

                .title {
                    height: 16px;
                    line-height: 16px;
                }

                .amounts {
                    margin: 8px 0 0 0;
                    height: 16px;
                    display: flex;
                    flex-direction: row;

                    .amounts_now {
                        width: 50%;
                        height: 16px;
                        line-height: 16px;
                    }

                    .amounts_total {
                        width: 50%;
                        height: 16px;
                        line-height: 16px;
                        position: relative;
                        // display: flex;
                        // flex-direction: row;
                        // justify-content: right;

                        .amounts_total_text {
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                    }
                }

                .progressbar {
                    margin: 6px 0 0 0;
                    height: 6px;
                    border-radius: 8px;
                    background-color: rgba(#fff, 0.1);
                    position: relative;

                    .progressbar_now {
                        position: absolute;
                        height: 6px;
                        top: 0;
                        left: 0;
                        background-color: #f29077;
                        border-radius: 8px 0 0 8px;
                    }
                }
            }

            .referralcheck {
                margin: 10px 0 0 0;
                height: 20px;
                display: flex;
                flex-direction: row;

                @media (max-width: $mobile-size) {
                    display: none;
                }

                .referralcheck_ico {
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../../../../../resources/ico-referrallink-x.svg");
                    margin: 0 6px 0 0;

                    &.reftrue {
                        background-image: url("../../../../../../resources/ico-referrallink-o.svg");
                    }
                }

                .referralcheck_title {
                    height: 15px;
                    line-height: 15px;
                    margin: 3px 0 0 0;
                }
            }
        }
    }

    .mobile_distribute {
        margin: 10px 0 0 0;
        width: 100%;
        height: 52px;
        display: none;

        @media (max-width: $mobile-size) {
            display: flex;
            flex-direction: column;
        }

        .title {
            height: 16px;
            line-height: 16px;
        }

        .amounts {
            margin: 8px 0 0 0;
            height: 16px;
            display: flex;
            flex-direction: row;

            .amounts_now {
                width: 50%;
                height: 16px;
                line-height: 16px;
            }

            .amounts_total {
                width: 50%;
                height: 16px;
                line-height: 16px;
                position: relative;
                // display: flex;
                // flex-direction: row;
                // justify-content: right;

                .amounts_total_text {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }

        .progressbar {
            margin: 6px 0 0 0;
            height: 6px;
            border-radius: 8px;
            background-color: rgba(#fff, 0.1);
            position: relative;

            .progressbar_now {
                position: absolute;
                height: 6px;
                top: 0;
                left: 0;
                background-color: #f29077;
                border-radius: 8px 0 0 8px;
            }
        }
    }

    .mobile_referralcheck {
        margin: 10px 0 0 0;
        height: 20px;
        display: none;

        @media (max-width: $mobile-size) {
            display: flex;
            flex-direction: row;
        }

        .referralcheck_ico {
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: url("../../../../../../resources/ico-referrallink-x.svg");
            margin: 0 6px 0 0;

            &.reftrue {
                background-image: url("../../../../../../resources/ico-referrallink-o.svg");
            }
        }

        .referralcheck_title {
            height: 15px;
            line-height: 15px;
            margin: 3px 0 0 0;
        }
    }
}
