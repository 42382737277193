@import "mixins";
@import "variables";

.container {
    @include container2();
    position: relative;

    .header {
        margin-bottom: 10px;
        padding: 30px 20px;
        border-radius: 5px;
        border: solid 1px #2e2b29;
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
            width: 100px;
            height: 28px;
            background-image: url("../../../../resources/img-t-a.svg");
        }

        .text1 {
            margin-top: 12px;
            text-align: center;
            @include fontLight(12px, white);
        }

        .text2 {
            margin-top: 10px;
            text-align: center;
            @include fontLight(12px, #786f64);
            line-height: 15px;
        }
    }
}

.inner_container {
    display: flex;
    flex-direction: column;
    padding-top: 40px;

    .summary {
        margin-bottom: 60px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 8px;
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
        background-color: #141418;

        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            text-align: center;
            justify-content: center;
            flex-wrap: wrap;
            word-break: break-all;
            @include font(24px, #ffe646);
            small {
                font-size: 12px;
            }
        }
    }

    .exchangeicon {
        height: 30px;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: $mobile-size) {
            flex-direction: column;
        }

        .icon {
            width: 28px;
            height: 28px;

            margin-left: 11px;
            margin-right: 11px;

            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("../../../../resources/icon-sum.svg");
        }
    }
}
