@import "mixins";
@import "variables";

.container_myreward {
    border-radius: 2px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.05);
    display: flex;
    flex-direction: row;

    @media (max-width: $mobile-size) {
        flex-direction: column;
        height: 387px;
    }

    .container_myreward_amount {
        width: calc(100% - 317px);
        height: 239px;
        display: flex;
        flex-direction: column;

        @media (max-width: $mobile-size) {
            display: none;
        }

        .amount_title {
            height: 59px;
            width: 100%;
            display: flex;
            flex-direction: row;

            .amount_title_participation {
                width: 155px;
                height: 100%;

                .amount_title_participation_text {
                    margin: 34px 0 0 30px;
                }
            }

            .amount_title_locked {
                width: 328px;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: right;

                .amount_title_locked_text {
                    margin: 34px 0 4px 0;
                }

                .amount_title_locked_ico {
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    margin: 28px 0 0 0;
                    background-image: url("../../../resources/ico-feelock01.svg");
                }
            }

            .amount_title_claimable {
                width: calc(100% - 483px);
                height: 100%;

                .amount_title_claimable_text {
                    margin: 34px 50px 0 0;
                    float: right;
                }
            }
        }

        .amount_contents {
            height: 59px;
            width: 100%;
        }

        .amount_dummy {
            width: 100%;
            height: 1px;
            background: rgba(#fff, 0.2);
        }
    }

    .container_myreward_dummy {
        width: 1px;
        height: 239px;
        background: rgba(#fff, 0.2);

        @media (max-width: $mobile-size) {
            display: none;
        }
    }

    .container_myreward_buttonarea {
        width: 316px;
        height: 239px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media (max-width: $mobile-size) {
            display: none;
        }

        .myreward_button {
            width: 160px;
            height: 36px;
            margin: 104px 0 0 0;
        }
    }

    .mobile_container_myreward_amount {
        display: none;

        @media (max-width: $mobile-size) {
            display: flex;
            flex-direction: column;
            height: 387px;
            width: 100%;

            .mobile_amount_title {
                .mobile_amount_title_locked {
                    .mobile_amount_title_locked_ico {
                        width: 20px;
                        height: 20px;
                        background-size: cover;
                        margin: 28px 0 0 0;
                        background-image: url("../../../resources/ico-feelock01.svg");
                    }
                }
            }

            .mobile_amount_contents {
                height: 99px;
                width: 100%;
            }

            .mobile_amount_dummy {
                width: 100%;
                height: 1px;
                background: rgba(#fff, 0.2);
            }
        }
    }

    .mobile_container_myreward_buttonarea {
        display: none;
        height: 86px;

        .mobile_myreward_button {
            height: 36px;
            margin: 6px 0 0 0;
            width: calc(100% - 40px);
        }

        @media (max-width: $mobile-size) {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
}

.d_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    .info {
        width: 155px;
        display: flex;
        flex-direction: row;

        .info_ico {
            width: 20px;
            height: 20px;
            margin: 20px 4px 0 30px;
            background-size: cover;
        }

        .info_text {
            line-height: 62px;
        }
    }

    .locked {
        width: 328px;
        display: flex;
        flex-direction: row;
        justify-content: right;

        .lockedwrap {
            display: flex;
            flex-direction: row;

            .lockedwrap_second {
                margin: 24px 0 0 0;
            }
        }
    }

    .claimable {
        width: calc(100% - 533px);
        display: flex;
        flex-direction: row;
        justify-content: right;
        margin: 0 -100px 0 0;

        .claimwrap {
            display: flex;
            flex-direction: row;

            .claimwrap_second {
                margin: 24px 0 0 0;
            }
        }
    }
}

.mobile_d_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .mobile_info {
        display: flex;
        flex-direction: row;
        margin: 20px auto 0 auto;
        width: calc(100% - 40px);

        .mobile_info_ico {
            width: 20px;
            height: 20px;
            margin: 0 6px 0 0;
            background-size: cover;
        }

        .mobile_info_text {
            line-height: 22px;
        }
    }

    .mobile_contents {
        height: 41px;
        width: calc(100% - 40px);
        margin: 7px auto 0 auto;
        display: flex;
        flex-direction: row;

        .mobile_contents_locked {
            width: calc(50% - 1px);
            height: 41px;
            display: flex;
            flex-direction: column;
            justify-content: left;

            .mobile_contents_locked_title {
                height: 20px;
                width: 100%;
                display: flex;
                flex-direction: row;

                .mobile_locked_text {
                    line-height: 20px;
                }

                .mobile_locked_ico {
                    width: 20px;
                    height: 20px;
                    margin: -2px 0 0 4px;
                    background-size: cover;
                    background-image: url("../../../resources/ico-feelock01.svg");
                }
            }

            .mobile_contents_locked_amount {
                height: 19px;
                width: 100%;
                margin: 2px 0 0 0;
                line-height: 19px;

                .mobile_lockedwrap {
                    display: flex;
                    flex-direction: row;

                    .mobile_lockedwrap_second {
                        margin: 1px 0 0 0;

                        &.iszero {
                            margin: 1px 0 0 3px;
                        }
                    }
                }
            }
        }

        .mobile_contents_dummy {
            width: 1px;
            height: 20px;
            background: rgba(#fff, 0.2);
            margin: 14px 0 0 0;
        }

        .mobile_contents_claimable {
            width: calc(50%);
            height: 41px;
            display: flex;
            flex-direction: column;

            .mobile_contents_claimable_title {
                height: 20px;
                width: 100%;
                position: relative;
                .mobile_claimable_text {
                    position: absolute;
                    right: 0;
                    top: 4px;
                }
            }

            .mobile_contents_claimable_amount {
                height: 19px;
                width: 100%;
                margin: 2px 0 0 0;
                line-height: 19px;
                position: relative;

                .mobile_contents_claimable_text {
                    position: absolute;
                    right: 0;
                    top: 0;

                    .mobile_claimwrap {
                        display: flex;
                        flex-direction: row;

                        .mobile_claimwrap_second {
                            margin: 1px 0 0 0;

                            &.iszero {
                                margin: 1px 0 0 3px;
                            }
                        }
                    }
                }
            }
        }
    }
}
