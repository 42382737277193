@import "mixins";
@import "variables";

$combosize: 144px;

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    // min-height: 120px;
    .title_container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title_container_v2 {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .balance_container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .title {
                @media (max-width: $mobile-size) {
                    display: none;
                }
            }

            .value {
                cursor: pointer;

                &.disabled {
                    cursor: auto;
                }
            }
        }
    }

    .body_container {
        border-radius: 10px;
        margin-top: 8px;

        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .inputico {
            border-radius: 25px;
            border: solid 0.5px rgba(255, 255, 255, 0.2);
            background-color: #000;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 11px;
            left: 10px;

            .inputico_vkr {
                background-image: url("../../../resources/input-logo-vkr.svg");
                width: 22px;
                height: 22px;
                margin: 4px auto 0 auto;
            }

            .inputico_vp {
                background-image: url("../../../resources/input-logo-lp.svg");
                width: 24px;
                height: 24px;
                // margin: 5px auto 0 auto;
            }

            .inputico_ust {
                background-image: url("../../../resources/input-logo-trade.svg");
                width: 22px;
                height: 22px;
                margin: 4px auto 0 auto;
            }

            .inputico_lp {
                background-image: url("../../../resources/input-logo-lp.svg");
                width: 24px;
                height: 24px;
            }
        }

        .field {
            @include font(18px, #fff);

            border-radius: 2px;

            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 19px;
            padding-right: 19px;
            width: 10px;
            flex: 1;
            height: 50px;
            outline: none;
            -webkit-appearance: none;

            &.combo {
                padding-right: 163px;

                @media (max-width: 320px) {
                    padding-right: $combosize * 0.8 + 19;
                }
            }

            background-color: rgba(#141418, 0.6);
            border: solid 1px rgba(255, 255, 255, 0.3);

            &.showSymbolIcon {
                padding-left: 50px;

                &:focus {
                    padding-left: 50px;
                }

                &.error {
                    padding-left: 50px;
                }
                &.disabled {
                    padding-left: 50px;
                }
            }

            &:focus {
                border: solid 3px #a500ff;
                height: 46px;
                // width: 377px;
                padding-left: 17px;
                background: #fff;
                color: black;

                &::placeholder {
                    color: rgba(0, 0, 0, 0.3);
                }
            }

            &.error {
                height: 46px;
                padding-left: 17px;
                border: solid 3px #a02d46;
                color: #a02d46;
            }

            &.disabled {
                height: 52px;
                padding-left: 20px;
                border: 0px solid #141818;
                box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
                background-color: #141418;
            }

            &::placeholder {
                color: rgba(255, 255, 255, 0.3);
            }
        }

        .symbol {
            @include font(14px, #fff);

            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
        }

        .combobox {
            cursor: pointer;
            position: absolute;
            z-index: 10000;
            right: 1px;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            width: $combosize;
            height: 58px;

            display: flex;
            flex-direction: row;
            align-items: center;

            @media (max-width: 320px) {
                width: $combosize * 0.8;
            }

            .dummy {
                width: 1px;
                height: 20px;
                background-color: rgba(white, 0.3);
            }

            .symbol {
                @include font(18px, rgba(white, 0.3));
                background-color: transparent;
                border: 0px;
                outline: none;
                -webkit-appearance: none;
            }

            .icon {
                width: 20px;
                height: 20px;
                margin-left: 12px;
                background-image: url("../../../resources/ico-drop.svg");
            }
        }

        &:focus-within {
            .symbol {
                color: black;
            }

            .combobox {
                .dummy {
                    background-color: #2d2d2d;
                }

                .symbol {
                    color: #2d2d2d;
                }

                .icon {
                    background-image: url("../../../resources/ico-drop-on.svg");
                }
            }
        }
    }

    .error_container {
        display: flex;
        flex-direction: row;
        height: 24px;
        margin-top: 6px;

        .error_text {
            @include fontLight(12px, #fd5214);
            word-break: break-all;
            padding-right: 12px;
            letter-spacing: -0.4px;
        }
    }
}
