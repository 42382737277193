@import "mixins";
@import "variables";

.pool {
    // height: 60px;
    width: 100%;
    border-radius: 2px;
    border: solid 1px rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.05);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    .wavewrapper {
        height: 21px; /* change the height of the waves here */
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-x: hidden;

        .wave {
            background-image: url("../../../../../resources/img-pool.svg");
            // filter: opacity(0.9) drop-shadow(0 0 0 #ffe646)
            //     saturate(1900%);
            width: 300%;
            height: 100%;
            animation: wave 40s -3s linear infinite;
            transform: translate3d(0, 0, 0);
            opacity: 0.5;
        }

        .wave:nth-of-type(2) {
            position: absolute;
            width: 300%;
            bottom: 0;
            animation: wave 15s linear infinite;
            opacity: 0.1;
        }

        @keyframes wave {
            0% {
                transform: translateX(0);
            }
            50% {
                transform: translateX(-25%);
            }
            100% {
                transform: translateX(-50%);
            }
        }
    }

    @media (max-width: $mobile-size) {
        // height: 85px;
        flex-direction: column;
        justify-content: start;
    }

    .pooltitle {
        margin: 20px 0 20px 20px;
        width: 134px;
        line-height: 20px;
        display: flex;
        flex-direction: row;

        @media (max-width: $mobile-size) {
            margin: 20px 0 0 20px;
        }

        .pooltitle_ico {
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: url("../../../../../resources/ico-pool01.svg");
        }

        .pooltitle_text {
            margin: 0 0 0 6px;
        }
    }

    .poolamount {
        // background: yellow;
        margin: 20px 20px 20px 0;
        width: 628px;
        // height: 20px;
        line-height: 20px;
        // display: flex;
        // flex-direction: row;
        position: relative;
        // justify-content: right;

        @media (max-width: $mobile-size) {
            justify-content: left;
            margin: 8px 0 0 20px;
            width: calc(100% - 20px);
            height: 40px;

            &.amountlong {
                height: 60px;
            }
        }

        .poolamount_text {
            position: absolute;
            top: 0;
            right: 0;

            @media (max-width: $mobile-size) {
                left: 0;
                right: auto;
            }

            .sub {
                display: flex;
                flex-direction: row;
                // align-items: center;

                @media (max-width: $mobile-size) {
                    flex-direction: column;
                    // align-items: left;
                    // justify-content: left;
                }

                .text2 {
                    @include font(16px, white);

                    // @media (max-width: $mobile-size) {
                    //     @include font(14px, white);
                    // }

                    small {
                        font-size: 12px;

                        // @media (max-width: $mobile-size) {
                        //     @include font(10px, white);
                        // }
                    }

                    &.isZero {
                        color: #e94f4f;
                    }
                }

                .dummy {
                    @include font(16px, white);

                    @media (max-width: $mobile-size) {
                        // @include font(14px, white);
                        display: none;
                    }

                    small {
                        font-size: 12px;

                        // @media (max-width: $mobile-size) {
                        //     @include font(10px, white);
                        // }
                    }
                }

                .text3 {
                    @include font(16px, white);

                    // @media (max-width: $mobile-size) {
                    //     margin-top: 6px;
                    //     @include font(14px, white);
                    // }

                    small {
                        font-size: 12px;

                        // @media (max-width: $mobile-size) {
                        //     @include font(10px, white);
                        // }
                    }

                    &.isZero {
                        color: #e94f4f;
                    }
                }
            }
        }
    }
}
