@import "mixins";
@import "variables";

.wrap {
    width: 100%;
    height: calc(100% + 300px);

    position: relative;
    z-index: 0;

    @media (max-width: $mobile-size) {
        height: calc(100%);
    }

    .container {
        @include container1();
        position: relative;
        margin: 0 auto;

        &.hash {
            margin: 40px auto 0 auto;
        }

        .filter_container {
            margin-bottom: 20px;
            margin-top: 40px;
            display: flex;
            flex-direction: row;
            width: 100%;
        }
        .item_container {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;

            @media (max-width: $mobile-size) {
                margin-top: 0px;
                padding-top: 0px;
            }
        }

        .comingsoon {
            margin-bottom: 40px;
            border-radius: 2px;
            background-color: #222630;
            overflow: hidden;
            position: relative;
        }
    }
}
