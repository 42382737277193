@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 75px;

    align-items: center;

    @media (max-width: $mobile-size) {
        width: 100%;
    }

    .button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        .text {
            @include fontBold(16px, rgba(white, 0.2));
        }

        .bar {
            // width: 100px;
            height: 2px;
            background-color: transparent;
        }

        &.selected {
            .text {
                @include fontBold(16px, #ffe646);
            }

            .bar {
                // width: 100px;
                height: 2px;
                background-color: #ffe646;
            }
        }

        &:hover {
            .text {
                @include fontBold(16px, #ffe646);
            }

            .bar {
                background-color: #ffe646;
            }
        }
    }

    .icon {
        margin-top: -2px;
        margin-left: 20px;
        margin-right: 20px;
        width: 20px;
        height: 20px;
        background-image: url("../../../../resources/ico-next-2.svg");
    }
}

.dummy_container {
    display: flex;
    flex-direction: row;

    height: 75px;

    align-items: center;

    @media (max-width: $mobile-size) {
        height: 30px;
    }
}
