@import "mixins";
@import "variables";

.wrap {
    width: 100%;
    position: relative;
    z-index: 0;

    @media (max-width: $mobile-size) {
        height: calc(100%);
    }

    .bg_footer {
        // top: $header-pc;
        top: 0;
        left: 0px;
        width: 100vw;
        height: 100vh;
        position: fixed;

        z-index: -1;

        background-image: radial-gradient(
            circle at 0 100%,
            rgba(#f29077, 0.7),
            #000
        );
    }

    .container {
        @include container2();
        position: relative;
        margin: 0 auto;

        &.hash {
            margin: 40px auto 0 auto;
        }

        .ipctopbanner {
            width: calc(100% + 2000px);
            height: 40px;
            margin: 0 0 0 -1000px;
        }

        .ipcbeforeopen {
            width: 100%;
            height: 70px;
            margin: 30px 0 -20px 0;
        }

        .header_container {
            width: 100%;
            display: flex;
            flex-direction: row;

            // margin-top: -52px;
            margin-bottom: 40px;

            &.isIpc {
                margin-top: 40px;
                margin-bottom: -20px;
            }

            @media (max-width: $mobile-size) {
                margin-top: 0px;
                flex-direction: column;
            }

            .header_myrewards {
                width: 520px;
                height: 400px;
                margin: 0 30px 0 0;
                display: flex;
                flex-direction: column;

                @media (max-width: $mobile-size) {
                    width: calc(100%);
                    margin: 0 auto;
                    height: 380px;
                }

                .header_myrewards_title {
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    margin: 0 0 20px 0;
                }

                .header_myrewards_wrap {
                    width: 100%;
                    // height: calc(100% + 300px);

                    background-color: rgba(0, 0, 0, 0.2);
                    position: relative;
                    z-index: 2;

                    .header_myrewards_bg_footer {
                        top: 0;
                        left: 0px;
                        width: calc(100% + 2px);
                        border-radius: 2px;
                        height: calc(100%);
                        position: absolute;
                        background-image: linear-gradient(
                            289deg,
                            #23293b,
                            #39425d
                        );
                        z-index: -1;

                        &.isIpc {
                            background-image: linear-gradient(
                                240deg,
                                rgba(#f29077, 0.5),
                                rgba(242, 144, 119, 0)
                            );
                        }

                        @media (max-width: $mobile-size) {
                            &.isIpc {
                                height: 298px;
                            }
                        }
                    }

                    .header_myrewards_contents {
                        width: 100%;
                        height: 356px;
                        border-radius: 2px;
                        border: solid 1px rgba(255, 255, 255, 0.3);
                        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
                        display: flex;
                        flex-direction: column;

                        &.isIpc {
                            height: 296px;
                        }

                        @media (max-width: $mobile-size) {
                            display: none;
                        }

                        .myrewards_contents_titles {
                            width: 100%;
                            height: 59px;
                        }

                        .myrewards_contents_dummy {
                            width: 520px;
                            height: 1px;
                            opacity: 0.2;
                            background-color: #fff;
                            margin: 0 auto;

                            @media (max-width: $mobile-size) {
                                width: calc(100%);
                            }
                        }

                        .myrewards_claim {
                            height: 116px;
                            width: 100%;

                            .myrewards_claimbutton {
                                width: calc(100% - 60px);
                                height: 36px;
                                margin: 40px auto 0 auto;
                            }
                        }
                    }

                    .mobile_header_myrewards_contents {
                        width: 100%;
                        height: 387px;
                        // opacity: 0.7;
                        // transform: rotate(-180deg);
                        border-radius: 2px;
                        border: solid 1px rgba(255, 255, 255, 0.3);
                        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
                        // background-image: linear-gradient(37deg, #f035f0, #000);
                        display: none;

                        @media (max-width: $mobile-size) {
                            display: flex;
                            flex-direction: column;
                            &.isIpc {
                                height: 296px;
                            }
                        }

                        .mobile_myrewards_contents_titles {
                            height: 69px;
                            width: calc(100% - 38px);
                            margin: 19px auto 0 auto;
                        }

                        .mobile_myrewards_contents_dummy {
                            width: 100%;
                            height: 1px;
                            background: rgba(255, 255, 255, 0.3);
                            margin: 13px 0 0 0;
                        }

                        .mobile_myrewards_claim {
                            height: 116px;
                            width: 100%;

                            .mobile_myrewards_claimbutton {
                                width: calc(100% - 40px);
                                height: 36px;
                                margin: 25px auto 0 auto;
                            }
                        }
                    }
                }
            }

            .header_mysharing {
                width: 250px;
                height: 400px;
                display: flex;
                flex-direction: column;

                @media (max-width: $mobile-size) {
                    width: calc(100%);
                    margin: 70px 0 0 0;

                    &.isIpc {
                        margin: 0;
                    }
                }

                .header_mysharing_title {
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    margin: 0 0 20px 0;
                }

                .header_mysharing_wrap {
                    width: 100%;
                    // height: calc(100% + 300px);

                    background-color: rgba(0, 0, 0, 0.2);
                    position: relative;
                    z-index: 2;

                    @media (max-width: $mobile-size) {
                        // height: calc(100%);
                    }

                    .header_mysharing_bg_footer {
                        top: 0;
                        left: 0px;
                        width: calc(100% + 2px);
                        border-radius: 2px;
                        height: calc(100%);
                        position: absolute;
                        background-image: linear-gradient(
                            289deg,
                            #23293b,
                            #39425d
                        );
                        z-index: -1;

                        &.isIpc {
                            background-image: linear-gradient(
                                240deg,
                                rgba(#f29077, 0.5),
                                rgba(242, 144, 119, 0)
                            );
                            height: calc(100%);
                        }
                    }

                    .header_mysharing_contents {
                        width: 100%;
                        height: 356px;
                        // opacity: 0.7;
                        // transform: rotate(-180deg);
                        border-radius: 2px;
                        border: solid 1px rgba(255, 255, 255, 0.3);
                        box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
                        // background-image: linear-gradient(37deg, #f035f0, #000);
                        display: flex;
                        flex-direction: column;

                        &.isIpc {
                            height: 296px;
                        }

                        .header_mysharing_link {
                            width: 100%;
                            height: 240px;
                            display: flex;
                            flex-direction: column;

                            &.isIpc {
                                height: 180px;
                            }

                            .link_ico {
                                width: 60px;
                                height: 60px;
                                background-size: cover;
                                background-image: url("../../../../resources/ico-sharing-link.svg");
                                margin: 50px auto 0 auto;

                                &.isIpc {
                                    margin: 30px auto 0 auto;
                                }
                            }

                            .link_title {
                                width: 125px;
                                height: 20px;
                                margin: 20px auto 0 auto;
                                display: flex;
                                flex-direction: row;

                                &.isIpc {
                                    margin: 10px auto 0 auto;
                                }

                                @media (max-width: $mobile-size) {
                                    margin: 27px auto 0 auto;
                                }

                                .link_title_name {
                                    line-height: 22px;
                                }

                                .link_title_ico {
                                    width: 20px;
                                    height: 20px;
                                    background-size: cover;
                                    margin: 0 0 0 6px;
                                    background-image: url("../../../../resources/ico-link-tooltip.svg");
                                }
                            }

                            .link_button {
                                width: 120px;
                                height: 30px;
                                margin: 10px auto 0 auto;
                            }
                        }

                        .header_mysharing_dummy {
                            width: 250px;
                            height: 1px;
                            opacity: 0.2;
                            background-color: #fff;

                            @media (max-width: $mobile-size) {
                                width: calc(100%);
                            }
                        }

                        .header_mysharing_cap {
                            width: 100%;
                            height: 116px;
                            display: flex;
                            flex-direction: column;

                            .mysharing_rewardcap {
                                width: calc(100% - 60px);
                                height: 20px;
                                margin: 20px auto 0 auto;
                                line-height: 20px;
                            }

                            .mysharing_max {
                                width: calc(100% - 60px);
                                height: 15px;
                                margin: 1px 0 0 30px;
                                line-height: 15px;
                                cursor: pointer;
                            }

                            .mysharing_percentage {
                                width: calc(100% - 60px);
                                height: 23px;
                                margin: 8px auto 0 auto;
                                border-radius: 2px;
                                // background-color: #2e2b29;

                                position: relative;
                                overflow: hidden;

                                .bar_title {
                                    height: 12px;
                                    width: 100%;
                                    line-height: 12px;

                                    .barover {
                                        display: flex;
                                        flex-direction: row;
                                        margin: -4px 0 0 0;

                                        .barover_ico {
                                            width: 20px;
                                            height: 20px;
                                            background-size: cover;
                                            margin: 0 6px 0 0;
                                            background-image: url("../../../../resources/ico-warning01.svg");
                                        }
                                    }
                                }

                                .defaultbar {
                                    position: absolute;
                                    left: 0px;
                                    top: 17px;
                                    height: 6px;
                                    width: 100%;
                                    border-radius: 8px;
                                    background-color: rgba(255, 255, 255, 0.5);
                                }

                                .bar {
                                    position: absolute;
                                    left: 0px;
                                    top: 17px;
                                    height: 6px;
                                    width: 100%;
                                    border-radius: 8px;
                                    background-color: #ffe646;
                                }
                            }
                        }
                    }
                }
            }

            .button_container {
                // width: 152px;
                height: 32px;
                display: flex;
                flex-direction: row;
                align-items: center;
                // align-self: flex-end;

                .button {
                    width: 152px;
                    height: 32px;
                }
            }

            .inner_container {
                @include box_shadow();
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                padding: 30px;

                .items {
                    display: flex;
                    flex-direction: row;

                    @media (max-width: $mobile-size) {
                        flex-direction: column;
                    }

                    .item {
                        flex: 1;
                        min-height: calc(67px - 43px);
                        padding-left: 20px;
                        padding-top: 12px;
                        padding-bottom: 12px;
                        padding-right: 20px;
                        border-radius: 8px;
                        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
                        background-color: #141418;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .received_rate {
                            height: 4px;
                            margin: 8px 0 7px;
                            border-radius: 2px;
                            background-color: #2e2b29;

                            position: relative;
                            overflow: hidden;

                            .bar {
                                position: absolute;
                                left: 0px;
                                top: 0px;
                                height: 100%;
                                background-color: #ffe646;
                            }
                        }

                        .receivable_value {
                            // margin-top: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;

                            .bar1 {
                                flex: 1;
                                height: 6px;
                                border-left: solid 1px #2e2b29;
                                border-bottom: solid 1px #2e2b29;
                            }

                            .icon {
                                margin-top: -2px;
                                margin-right: 1px;
                                width: 16px;
                                height: 14px;
                                background-size: cover;
                                background-image: url("../../../../resources/ico-warning3.svg");
                            }

                            .bar2 {
                                flex: 1;
                                height: 6px;
                                border-right: solid 1px #2e2b29;
                                border-bottom: solid 1px #2e2b29;
                            }
                        }
                    }
                }
                .dummy {
                    width: 30px;
                    height: 30px;
                }
            }

            .mylink {
                position: relative;

                flex: 1;
                height: 130px;
                padding-left: 20px;
                padding-right: 20px;
                border-radius: 8px;

                background-color: #222226;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @media (max-width: $mobile-size) {
                    height: auto;
                    flex-direction: column;

                    margin-top: 20px;
                }

                .col1 {
                    margin-top: 25px;

                    display: flex;
                    flex-direction: row;
                    overflow: hidden;

                    @media (max-width: $mobile-size) {
                        margin-bottom: 60px;
                        align-self: flex-start;
                    }

                    .icon {
                        width: 44px;
                        height: 44px;
                        flex: none;
                        background-size: cover;
                        background-image: url("../../../../resources/ico-link-2.svg");
                    }

                    .info {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        overflow: hidden;
                        margin-left: 12px;

                        .text2 {
                            margin-top: 8px;
                            overflow: hidden;
                            // text-overflow: ellipsis;
                            word-break: break-all;
                            @include font(14px, white);
                        }
                    }
                }

                .copy_button {
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    display: flex;

                    .button {
                        cursor: pointer;

                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .icon {
                            background-size: contain;
                            width: 18px;
                            height: 18px;

                            background-image: url("../../../../resources/ico-paste-wallet.svg");
                        }

                        .text {
                            margin-left: 5px;
                            text-decoration: underline;
                            @include font(14px, rgba(white, 0.3));
                        }

                        &:hover {
                            .icon {
                                background-image: url("../../../../resources/ico-paste-wallet-on.svg");
                            }

                            .text {
                                color: #ffe646;
                            }
                        }
                    }
                }
            }
        }
    }
}

.ptitle_container {
    width: calc(100% - 60px);
    height: 15px;
    margin: 34px auto 0 auto;
    display: flex;
    flex-direction: row;

    .ptitle_info {
        width: 200px;
        line-height: 15px;
    }

    .ptitle_locked {
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;

        .ptitle_locked_title {
            line-height: 15px;
        }

        .ptitle_locked_ico {
            width: 20px;
            height: 20px;
            margin: -4px 0 0 4px;
            background-size: cover;
            background-image: url("../../../../resources/ico-feelock01.svg");
        }
    }

    .ptitle_claimable {
        width: 130px;
        display: flex;
        flex-direction: row;
        justify-content: right;
    }
}

.pdetail_container {
    width: calc(100% - 60px);
    height: 20px;
    margin: 20px auto 0 auto;
    display: flex;
    flex-direction: row;

    .pdetail_info {
        width: 200px;
        height: 100%;
        display: flex;
        flex-direction: row;

        .pdetail_info_ico {
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: url("../../../../resources/ico-rewards-01.svg");

            &.sharing {
                background-image: url("../../../../resources/ico-rewards-02.svg");
            }

            &.deposit {
                background-image: url("../../../../resources/ico-rewards-03.svg");
            }
        }

        .pdetail_info_title {
            line-height: 23px;
            margin: 0 0 0 6px;
        }
    }

    .pdetail_locked {
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;

        .pdetail_locked_wrap {
            display: flex;
            flex-direction: row;
            justify-content: right;
            .pdetail_locked_amount {
                line-height: 22px;
            }

            .pdetail_locked_symbol {
                margin: 0 0 0 4px;
                line-height: 24px;
            }
        }
    }

    .pdetail_claimable {
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;

        .pdetail_claimable_wrap {
            display: flex;
            flex-direction: row;
            justify-content: right;
            .pdetail_claimable_amount {
                line-height: 20px;
            }

            .pdetail_claimable_symbol {
                margin: 0 0 0 4px;
                line-height: 22px;
            }
        }
    }
}

.mobile_pdetail_container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .mobile_title {
        height: 20px;
        margin: 0 0 10px 0;
        display: flex;
        flex-direction: row;

        .mobile_title_ico {
            width: 20px;
            height: 20px;
            background-size: cover;
            margin: 0 6px 0 0;
            background-image: url("../../../../resources/ico-rewards-01.svg");

            &.sharing {
                background-image: url("../../../../resources/ico-rewards-02.svg");
            }

            &.deposit {
                background-image: url("../../../../resources/ico-rewards-03.svg");
            }
        }

        .mobile_title_text {
            line-height: 22px;
        }
    }

    .mobile_contents {
        height: 38px;
        display: flex;
        flex-direction: row;

        .mobile_contents_locked {
            width: calc(50% - 1px);
            height: 100%;
            display: flex;
            flex-direction: column;

            .mobile_contents_locked_title {
                height: 20px;
                display: flex;
                flex-direction: row;

                .locked_title_text {
                    line-height: 20px;
                }

                .locked_title_ico {
                    width: 20px;
                    height: 20px;
                    margin: -2px 0 0 4px;
                    background-size: cover;
                    background-image: url("../../../../resources/ico-feelock01.svg");
                }
            }

            .mobile_contents_locked_amount {
                height: 19px;
                .pdetail_locked_wrap {
                    display: flex;
                    flex-direction: row;

                    .pdetail_locked_amount {
                        line-height: 20px;
                    }

                    .pdetail_locked_symbol {
                        margin: 0 0 0 4px;
                        line-height: 24px;
                    }
                }
            }
        }

        .mobile_contents_dummy {
            width: 1px;
            height: 20px;
            margin: 11px 0 0 0;
            background: rgba(#fff, 0.2);
        }

        .mobile_contents_claimable {
            width: 50%;
            height: 100%;
            // background: yellow;
            display: flex;
            flex-direction: column;
            position: relative;

            .mobile_contents_claimable_title {
                height: 20px;
                // display: flex;
                // flex-direction: row;
                // justify-content: right;
                position: absolute;
                right: 0;
                top: 0;

                .claimable_title_text {
                    line-height: 20px;
                }
            }

            .mobile_contents_claimable_amount {
                height: 19px;
                .pdetail_claimable_wrap {
                    display: flex;
                    flex-direction: row;
                    // justify-content: right;
                    position: absolute;
                    right: 0;
                    top: 20px;

                    .pdetail_claimable_amount {
                        line-height: 22px;
                    }

                    .pdetail_claimable_symbol {
                        margin: 0 0 0 4px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}
