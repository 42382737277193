@import "mixins";
@import "variables";

.container {
    margin: 0 0 40px 0;

    .dummy {
        width: 800px;
        height: 1px;
        opacity: 0.2;
        background-color: #fff;

        &.isIpc {
            margin: 40px 0 0 0;
        }

        @media (max-width: $mobile-size) {
            width: 100%;
        }
    }

    .title {
        margin: 40px 0 20px 0;
        display: flex;
        flex-direction: row;

        .title_sub {
            margin: 2px 0 0 6px;
        }
    }

    .confirmbutton {
        margin: 30px auto 0px auto;
        height: 48px;
        width: 400px;

        @media (max-width: $mobile-size) {
            width: calc(100% - 60px);
        }

        .button {
            width: calc(100% - 4px);
            height: calc(100% - 2px);
        }
    }

    .confirmbutton2 {
        margin: -30px auto 0px auto;
        height: 48px;
        width: 400px;

        @media (max-width: $mobile-size) {
            width: calc(100%);
        }

        .button {
            width: calc(100% - 4px);
            height: calc(100% - 2px);
            border-radius: 2px;
            box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
            border: solid 1px rgba(255, 255, 255, 0.3);
            display: flex;
            flex-direction: row;
            justify-content: center;

            &.disabled {
                background-color: rgba(#fff, 0.1);
                cursor: not-allowed;

                .button_text {
                    color: rgba(#fff, 0.3);
                }

                .button_ico {
                    background-image: url("../../../../../resources/ico-arrow-coming-off.svg");
                }

                &:hover {
                    background-color: rgba(#fff, 0.1);
                    cursor: not-allowed;
                    border: solid 1px rgba(255, 255, 255, 0.3);

                    .button_text {
                        color: rgba(#fff, 0.3);
                    }

                    .button_ico {
                        background-image: url("../../../../../resources/ico-arrow-coming-off.svg");
                    }
                }
            }

            &:hover {
                border-radius: 2px;
                box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
                border: solid 1px #ffe646;
                cursor: pointer;

                .button_text {
                    color: #ffe646;
                }

                .button_ico {
                    background-image: url("../../../../../resources/ico-arrow-top-true.svg");
                }
            }

            .button_text {
                margin: 16px 0 0 0;
                @include fontBold(14px, #fff);
            }

            .button_ico {
                width: 20px;
                height: 20px;
                background-size: cover;
                margin: 11px 0 0 4px;
                background-image: url("../../../../../resources/ico-arrow-top.svg");
            }
        }
    }
}

.txwrap {
    width: 100%;
    height: calc(100% + 300px);

    background-color: rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 2;
    border: solid 1px rgba(255, 255, 255, 0.3);
    border-radius: 2px;

    @media (max-width: $mobile-size) {
        height: calc(100%);
    }

    .txbg_footer {
        top: 0;
        left: 0px;
        width: 100%;
        height: calc(100%);
        position: absolute;
        background-image: linear-gradient(289deg, #23293b, #39425d);
        z-index: -1;

        &.isIpc {
            background-image: radial-gradient(
                circle at 0 100%,
                rgba(242, 144, 119, 0),
                rgba(#f29077, 0.5)
            );

            box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
        }
    }

    .txcontainer {
        position: relative;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        .title {
            margin: 30px 0 0 30px;

            @media (max-width: $mobile-size) {
                margin: 20px 0 0 20px;
            }
        }

        .vkrcheck {
            height: 74px;
            width: calc(100% - 60px);
            margin: 20px auto 0 auto;

            @media (max-width: $mobile-size) {
                height: 108px;
                width: calc(100% - 40px);
            }
        }

        .numcheck {
            height: 74px;
            width: calc(100% - 60px);
            margin: 25px auto 0 auto;

            @media (max-width: $mobile-size) {
                height: 108px;
                width: calc(100% - 40px);
            }
        }

        .vpcheck {
            height: 74px;
            width: calc(100% - 60px);
            margin: 25px auto 0 auto;

            @media (max-width: $mobile-size) {
                height: 108px;
                width: calc(100% - 40px);
            }
        }

        .swapcheck {
            height: 74px;
            width: calc(100% - 60px);
            margin: 25px auto 0 auto;

            @media (max-width: $mobile-size) {
                height: 108px;
                width: calc(100% - 40px);
            }
        }

        .conditioncheck {
            width: calc(100% - 60px);
            margin: 40px auto 0 auto;
            height: 94px;
            border-radius: 5px;
            border: solid 1px #f29077;
            background-color: rgba(242, 144, 119, 0.1);

            @media (max-width: $mobile-size) {
                height: 143px;
                width: calc(100% - 40px);
            }
        }

        .txdummy {
            width: calc(100% - 60px);
            height: 1px;
            background: rgba(#fff, 0.2);
            margin: 40px auto 0 auto;

            @media (max-width: $mobile-size) {
                width: calc(100% - 40px);
            }
        }

        .confirmbutton {
            margin: 30px auto 40px auto;
            height: 48px;
            width: 400px;

            @media (max-width: $mobile-size) {
                width: calc(100% - 40px);
                margin: 20px auto 30px auto;
            }

            .button {
                width: calc(100% - 4px);
                height: calc(100% - 2px);
            }
        }
    }
}

.conditioncontainer {
    display: flex;
    flex-direction: column;

    .mobile_validcheck {
        width: calc(100%);
        height: 26px;
        display: none;

        @media (max-width: $mobile-size) {
            display: flex;
            flex-direction: row;
        }

        .mobile_validcheck_ico {
            width: 26px;
            height: 26px;
            background-size: cover;
            background-image: url("../../../../../resources/ipc-success01.svg");

            &.fail {
                background-image: url("../../../../../resources/ipc-no01.svg");
            }
        }

        .ipc_mobile_validcheck_ico {
            width: 26px;
            height: 26px;
            background-size: cover;
            background-image: url("../../../../../resources/ipc-ing.svg");

            &.success {
                background-image: url("../../../../../resources/ipc-success01.svg");
            }

            &.fail {
                background-image: url("../../../../../resources/ipc-no01.svg");
            }
        }

        .ipc_swap_mobile_validcheck_ico {
            width: 26px;
            height: 26px;
            background-size: cover;
            background-image: url("../../../../../resources/ipc-ing2.svg");

            &.success {
                background-image: url("../../../../../resources/ipc-success01.svg");
            }

            &.fail {
                background-image: url("../../../../../resources/ipc-no01.svg");
            }
        }

        .mobile_validcheck_text {
            line-height: 26px;
            margin: 0 0 0 10px;
        }

        .mobile_validcheck_text2 {
            margin: 0 0 0 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .balance {
        height: 16px;
        position: relative;

        @media (max-width: $mobile-size) {
            margin: 10px 0 0 0;
        }

        .balance_wrap {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            flex-direction: row;
            .balance_title {
                line-height: 16px;
            }

            .balance_amount {
                line-height: 16px;
                margin: 0 0 0 4px;
                display: flex;
                flex-direction: row;

                .balance_amount_symbol {
                    line-height: 18px;
                    margin: 0 0 0 4px;

                    &.participants {
                        margin: 0;
                    }

                    &.isIpc {
                        margin: 0 0 0 4px;
                    }
                }
            }
        }
    }

    .contents {
        height: 52px;
        margin: 6px 0 0 0;
        display: flex;
        flex-direction: row;

        .validcheck {
            width: calc(100% - 420px);
            height: 52px;
            display: flex;
            flex-direction: row;

            @media (max-width: $mobile-size) {
                display: none;
            }

            .validcheck_ico {
                width: 32px;
                height: 32px;
                background-size: cover;
                margin: 10px 0 0 0;
                background-image: url("../../../../../resources/ipc-success01.svg");

                &.fail {
                    background-image: url("../../../../../resources/ipc-no01.svg");
                }
            }

            .ipc_validcheck_ico {
                width: 32px;
                height: 32px;
                background-size: cover;
                margin: 10px 0 0 0;
                background-image: url("../../../../../resources/ipc-ing.svg");

                &.success {
                    background-image: url("../../../../../resources/ipc-success01.svg");
                }

                &.fail {
                    background-image: url("../../../../../resources/ipc-no01.svg");
                }
            }

            .ipc_swap_validcheck_ico {
                width: 32px;
                height: 32px;
                background-size: cover;
                margin: 10px 0 0 0;
                background-image: url("../../../../../resources/ipc-ing2.svg");

                &.success {
                    background-image: url("../../../../../resources/ipc-success01.svg");
                }

                &.fail {
                    background-image: url("../../../../../resources/ipc-no01.svg");
                }
            }

            .validcheck_text {
                line-height: 52px;
                margin: 0 0 0 10px;
            }

            .validcheck_text2 {
                margin: 0 0 0 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .inputfield_swaparea {
            width: 100%;
            margin: 0 0 0 150px;
            position: relative;

            @media (max-width: $mobile-size) {
                margin: 0;
            }

            .inputfield_swap {
                width: 400px;
                height: 52px;
                border-radius: 2px;
                box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
                background-color: rgba(#141418, 0.6);
                border: none;
                @include font(18px, #fff);
                padding: 0 0 0 20px;

                @media (max-width: $mobile-size) {
                    width: calc(100% - 20px);
                }
            }

            // input:focus {
            //     width: 394px;
            //     height: 46px;
            //     border-radius: 2px;
            //     border: solid 3px #f29077;
            //     background-color: #fff;
            //     outline: none;
            //     box-shadow: none;
            //     color: black;
            //     margin: 0 0 0 10px;

            //     @media (max-width: $mobile-size) {
            //         width: calc(100% - 26px);
            //         margin: 0;
            //     }
            // }

            // input:focus + .inputfield_symbol {
            //     @include font(14px, #000);
            //     position: absolute;
            //     top: 20px;
            //     right: 18px;
            // }

            // .inputfield_symbol {
            //     @include font(14px, #fff);
            //     position: absolute;
            //     top: 20px;
            //     right: 18px;
            // }
        }

        .inputfield {
            width: 420px;
            height: 52px;
            border-radius: 2px;
            box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
            background-color: rgba(#141418, 0.6);
            display: flex;
            flex-direction: row;

            @media (max-width: $mobile-size) {
                width: calc(100%);
            }

            .input_amount {
                width: calc(100% - 134px);
                height: 100%;

                .input_amount_container {
                    line-height: 52px;
                    margin: 0 0 0 20px;
                }
            }

            .input_symbol {
                width: 134px;
                height: 100%;

                .vkrcontainer_symbol {
                    display: flex;
                    flex-direction: column;
                    justify-content: right;

                    .vkrcontainer_symbol_text {
                        height: 26px;
                        line-height: 26px;
                        margin: 4px 0 0 83px;
                    }

                    .vkrcontainer_symbol_lockday {
                        height: 26px;
                        line-height: 26px;
                        margin: -4px 0 0 0;
                        .deposit_contents_day {
                            width: 90px;
                            display: flex;
                            flex-direction: row;
                            justify-content: right;
                            margin: -4px 0 0 28px;

                            .deposit_contents_day_text {
                                margin: 0 0 0 20px;
                                line-height: 28px;

                                @media (max-width: $mobile-size) {
                                    line-height: 30px;
                                }
                            }

                            .deposit_contents_day_ico {
                                width: 20px;
                                height: 20px;
                                margin: 2px 0 0 4px;
                                background-size: cover;
                                background-image: url("../../../../../resources/ico-feelock011.svg");

                                @media (max-width: $mobile-size) {
                                    margin: 3px 0 0 4px;
                                }
                            }
                        }
                    }
                }

                .vpcontainer_symbol {
                    height: 100%;
                    width: 164px;
                    margin: 0 0 0 -30px;
                    display: flex;
                    flex-direction: row;

                    .vpcontainer_symbol_text {
                        width: 20px;
                        height: 100%;
                        line-height: 52px;
                    }

                    .vpcontainer_symbol_dummy {
                        margin: 16px 10px 0 10px;
                        width: 1px;
                        height: 20px;
                        background: rgba(#fff, 0.3);
                    }

                    .vpcontainer_symbol_button {
                        width: 109px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .vpbutton {
                            width: 107px;
                            height: 30px;
                        }
                    }
                }

                .particontainer_symbol {
                    height: 100%;
                    width: 134px;
                    display: flex;
                    flex-direction: row;
                    justify-content: right;

                    .particontainer_symbol_text {
                        line-height: 52px;
                        margin: 0 20px 0 0;
                    }
                }
            }
        }
    }
}

.createvpcontainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 15px;
    border: solid 1px rgba(#fff, 0.3);

    &:hover {
        border-radius: 15px;
        border: 0;
        background-color: #ffe646;
        cursor: pointer;
        .vp_ico {
            background-image: url("../../../../../resources/ico-vp-t.svg");
        }

        .vp_text {
            color: black;
        }
    }

    .vp_ico {
        width: 20px;
        height: 20px;
        margin: 4px 4px 0 0;
        background-size: cover;
        background-image: url("../../../../../resources/ico-vp.svg");
    }

    .vp_text {
        line-height: 30px;
        @include font(12px, #fff);
    }
}

.particontainer2 {
    width: 100%;
    height: 94px;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        height: 143px;
    }

    .balance {
        height: 20px;
        width: calc(100% - 40px);
        margin: 20px auto 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: $mobile-size) {
            flex-direction: column;
            margin: 20px auto 15px auto;
        }

        .title2 {
            width: 240px;
            height: 20px;
            display: flex;
            flex-direction: row;

            .title2_ico {
                width: 20px;
                height: 20px;
                margin: 0 8px 0 0;
                background-size: cover;
                background-image: url("../../../../../resources/ipc-check2.svg");
            }

            .title2_text {
                line-height: 20px;
            }
        }

        .contents2 {
            width: 190px;
            height: 20px;
            line-height: 20px;
            display: flex;
            flex-direction: row;
            justify-content: right;

            @media (max-width: $mobile-size) {
                margin: 7px 0 0 0;
                width: 100%;
            }
        }
    }

    .price {
        height: 20px;
        width: calc(100% - 40px);
        margin: 16px auto 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: $mobile-size) {
            flex-direction: column;
            margin: 20px auto 15px auto;
        }

        .title2 {
            width: 240px;
            height: 20px;
            display: flex;
            flex-direction: row;

            .title2_ico {
                width: 20px;
                height: 20px;
                margin: 0 8px 0 0;
                background-size: cover;
                background-image: url("../../../../../resources/ipc-check2.svg");
            }

            .title2_text {
                line-height: 20px;
            }
        }

        .contents2 {
            width: 190px;
            height: 20px;
            line-height: 20px;
            display: flex;
            flex-direction: row;
            justify-content: right;

            @media (max-width: $mobile-size) {
                margin: 7px 0 0 0;
                width: 100%;
            }
        }
    }

    .unlock {
        height: 20px;
        width: calc(100% - 40px);
        margin: 16px auto 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: $mobile-size) {
            flex-direction: column;
            margin: 20px auto 15px auto;
        }

        .title2 {
            width: 240px;
            height: 20px;
            display: flex;
            flex-direction: row;

            .title2_ico {
                width: 20px;
                height: 20px;
                margin: 0 8px 0 0;
                background-size: cover;
                background-image: url("../../../../../resources/ipc-check2.svg");
            }

            .title2_text {
                line-height: 20px;
            }
        }

        .contents2 {
            width: 190px;
            height: 20px;
            line-height: 20px;
            display: flex;
            flex-direction: row;
            justify-content: right;

            @media (max-width: $mobile-size) {
                margin: 7px 0 0 0;
                width: 100%;
            }

            .contentswrap {
                display: flex;
                flex-direction: row;

                .contents2_date {
                    margin: 0 4px 0 0;
                }

                .contents2_lock {
                    height: 26px;
                    line-height: 26px;
                    margin: -5px 0 0 0;

                    .deposit_contents_day {
                        // width: 90px;
                        display: flex;
                        flex-direction: row;
                        justify-content: right;
                        // margin: -4px 0 0 28px;

                        .deposit_contents_day_text {
                            // margin: 0 0 0 20px;
                            line-height: 28px;

                            @media (max-width: $mobile-size) {
                                line-height: 30px;
                            }
                        }

                        .deposit_contents_day_ico {
                            width: 20px;
                            height: 20px;
                            margin: 2px 0 0 4px;
                            background-size: cover;
                            background-image: url("../../../../../resources/ico-feelock011.svg");

                            @media (max-width: $mobile-size) {
                                margin: 3px 0 0 4px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.particontainer3 {
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $mobile-size) {
        height: 203px;
    }

    .comingsoon {
        border-radius: 5px;
        border: solid 1px #f29077;
        background-color: rgba(242, 144, 119, 0.1);
        width: calc(100% - 60px);
        margin: 0 auto;
        height: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .textwrap {
            @include fontLight(16px, #f29077);
            text-align: center;
            margin: 40px 0 0 0;
        }
    }
}
