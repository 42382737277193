@import "mixins";
@import "variables";

.container {
    display: flex;
    flex-direction: column;

    .row {
        padding-left: 30px;
        padding-right: 20px;
        height: 60px;

        display: flex;
        flex-direction: row;
        align-items: center;

        .content {
            display: flex;
            flex-direction: row;
            flex: 1;
        }

        .button {
            width: 74px;
            height: 32px;
        }
    }
    .dummy {
        display: flex;
        flex-direction: column;

        .dummy1 {
            height: 1px;
            background-color: rgba(black, 0.3);
        }
        .dummy2 {
            height: 1px;
            background-color: rgba(white, 0.3);
        }
    }
}
