@import "mixins";
@import "variables";

.container {
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 2px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.05);

    .icon {
        width: 82px;
        height: 82px;
        background-size: cover;
        background-image: url("../../../resources/ico-popup-2-copy.svg");
        margin-bottom: 22px;
    }
    .text {
        @include fontLight(14px, white);
    }
}
