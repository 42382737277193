@import "mixins";
@import "variables";

.container {
    @include container2();
    align-items: flex-start;

    .box {
        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.05);
    }
}
