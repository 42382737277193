@import "mixins";
@import "variables";

.container {
    .fanfurycondition {
        display: flex;
        flex-direction: column;

        .conditiondummy1 {
            height: 1px;
            width: calc(100% + 60px);
            background: rgba(#000, 0.1);
            margin: 0 0 0 -30px;
        }

        .conditiondummy2 {
            height: 1px;
            width: calc(100% + 60px);
            background: rgba(#fff, 0.1);
            margin: 0 0 30px -30px;
        }

        .conditiontitle {
            height: 19px;
            width: 100%;
            margin: 0 0 20px 0;
            line-height: 19px;
        }

        .vkrcondition {
            margin: 0 0 22px 0;
        }

        .vpcondition {
            margin: 0 0 22px 0;
        }

        .swapcondition {
            display: flex;
            flex-direction: row;

            @media (max-width: $mobile-size) {
                flex-direction: column;
            }

            .swapconditiontitle {
                width: calc(100% - 420px);
                height: 53px;
                margin: 27px 0 0 0;
                display: flex;
                flex-direction: row;
                align-items: center;

                @media (max-width: $mobile-size) {
                    margin: 0;
                    width: 100%;
                }

                .swapicon {
                    width: 32px;
                    height: 32px;
                    background-size: cover;
                    background-image: url("../../../../../resources/ipc-success01.svg");

                    &.enable {
                        background-image: url("../../../../../resources/ipc-no01.svg");
                    }
                }

                .swaptitle {
                    margin: 0 0 0 10px;
                    @include font(14px, #55f559);
                }

                .swaptitlewrap {
                    display: flex;
                    flex-direction: column;
                    margin: 0 0 0 10px;

                    .swaptitlemain {
                        @include font(14px, #fd5214);
                    }

                    .swaptitlesub {
                        margin: 2px 0 0 0;
                        @include fontLight(12px, #fd5214);
                    }
                }
            }
        }

        .participationdummy {
            height: 1px;
            width: 100%;
            background: rgba(#fff, 0.3);
            margin: 0 0 30px 0;
        }

        .participation_button_container {
            display: flex;
            flex-direction: column;

            .estimate_container {
                height: 50px;
                border-radius: 2px;
                border: solid 1px rgba(255, 255, 255, 0.2);
                background-color: rgba(255, 255, 255, 0.1);

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .text {
                    text-align: center;
                    @include fontBold(18px, #ffe646);
                }
            }

            .participationbuttonwrap {
                margin: -10px 0 0 0;
            }
        }

        .remaining {
            margin: 0 0 40px 0;
        }
    }
}
