@import "mixins";
@import "variables";

.container {
    width: 80px;
    height: 80px;

    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        width: 80px;
        height: 80px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(#000, 0.5);

        &.pylon {
            background: rgb(14, 39, 67);
        }

        .icon_ico {
            width: 64px;
            height: 64px;
        }
    }

    .activefalse {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(#000, 0.5);
        position: absolute;
        @include fontBold(12px, #fff);
        text-align: center;

        &.pylon {
            background: rgb(14, 39, 67);
        }

        .closed {
            margin: 37px 0 0 0;
        }

        &.activefalse {
            background-color: rgba(#000, 0.8);
        }
    }
}

.container2 {
    width: 80px;
    height: 80px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobile-size) {
        width: 60px;
        height: 60px;
    }

    .icon {
        width: 80px;
        height: 80px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(#000, 0.5);

        @media (max-width: $mobile-size) {
            width: 60px;
            height: 60px;
        }

        &.pylon {
            background: rgb(14, 39, 67);
        }

        .icon_ico {
            width: 64px;
            height: 64px;

            @media (max-width: $mobile-size) {
                width: 44px;
                height: 44px;
            }
        }
    }

    .activefalse {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(#000, 0.5);
        position: absolute;
        @include fontBold(12px, #fff);
        text-align: center;

        @media (max-width: $mobile-size) {
            width: 60px;
            height: 60px;
            @include fontBold(10px, #fff);
        }

        &.pylon {
            background: rgb(14, 39, 67);
        }

        .closed {
            margin: 37px 0 0 0;

            @media (max-width: $mobile-size) {
                margin: 26px 0 0 0;
            }
        }

        &.activefalse {
            background-color: rgba(#000, 0.8);
        }
    }
}
.container3 {
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobile-size) {
        width: 40px;
        height: 40px;
    }

    .icon {
        width: 40px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(#000, 0.5);

        @media (max-width: $mobile-size) {
            width: 40px;
            height: 40px;
        }

        &.pylon {
            background: rgb(14, 39, 67);
        }

        &.lunart {
            background-color: transparent;
            border: transparent;
        }

        .icon_ico {
            width: 32px;
            height: 32px;

            &.lunart {
                width: 40px;
                height: 40px;
            }

            @media (max-width: $mobile-size) {
                width: 32px;
                height: 32px;
            }
        }
    }

    .activefalse {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(#000, 0.5);
        position: absolute;
        @include fontBold(12px, #fff);
        text-align: center;

        @media (max-width: $mobile-size) {
            width: 60px;
            height: 60px;
            @include fontBold(10px, #fff);
        }

        &.pylon {
            background: rgb(14, 39, 67);
        }

        .closed {
            margin: 37px 0 0 0;

            @media (max-width: $mobile-size) {
                margin: 26px 0 0 0;
            }
        }

        &.activefalse {
            background-color: rgba(#000, 0.8);
        }
    }
}
