@import "mixins";
@import "variables";

.container {
    @include box_shadow();

    width: 100%;
    // height: 130px;
    display: flex;
    margin-right: 40px;
    border-radius: 2px;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(302deg, #23293b, #39425d);
    flex: 0.36;
    .inner_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding: 30px;

        @media (max-width: $mobile-size) {
            padding: 20px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }

        .value {
            margin-top: 8px;
            display: flex;
            flex-direction: row;

            @media (max-width: $mobile-size) {
                width: 100%;
                // justify-content: flex-end;
            }
        }

        .dummy {
            flex: 1;
            @media (max-width: $mobile-size) {
                flex: none;
                height: 16px;
            }
        }
    }
}
