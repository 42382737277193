@import "mixins";
@import "variables";

.container {
    width: 100%;
    margin: 0 0 0 0;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        margin: 40px 0 -20px 0;

        &.disabled {
            margin: 0 0 20px 0;
        }
    }

    .title {
        height: 24px;
        line-height: 24px;
    }

    .guidewrap {
        display: flex;
        flex-direction: row;

        @media (max-width: $mobile-size) {
            flex-direction: column;
        }

        .box {
            margin: 20px 20px 0 0;
            width: calc(50%);
            // height: 190px;
            border-radius: 2px;
            border: solid 0.5px rgba(#fff, 0.2);

            @media (max-width: $mobile-size) {
                width: 100%;
            }

            .boxwrap {
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                margin: 20px auto 20px auto;
                display: flex;
                flex-direction: column;

                .boxwrap_title {
                    line-height: 19px;
                    word-break: break-all;
                }
                .boxwrap_contents_text {
                    line-height: 20px;
                    display: flex;
                    flex-direction: column;

                    margin: 10px 0 0 0;
                }
            }
            .boxwrap_contents_more {
                line-height: 20px;
                position: relative;
                height: 30px;
                width: calc(100% - 40px);
                margin: -20px auto 0 auto;

                // display: flex;
                // flex-direction: row;
                // justify-content: right;
                // margin: 20px 0 0 0;

                @media (max-width: $mobile-size) {
                    margin: -20px auto 0 auto;
                }

                .boxwrap_contents_more_text {
                    position: absolute;
                    right: 0;
                    top: -10px;
                    @media (max-width: $mobile-size) {
                        bottom: 10px;
                    }
                }

                a {
                    color: white;
                }
                a:link {
                    color: white;
                }
                a:visited {
                    color: white;
                }
            }
        }

        .detail {
            margin: 20px 0 0 20px;
            // height: 190px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: calc(50% - 20px);

            @media (max-width: $mobile-size) {
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin: 0 0 0 0;
            }

            .detail_swap {
                width: calc(100%);
                height: 190px;
                display: flex;
                flex-direction: column;

                @media (max-width: $mobile-size) {
                    margin: 20px 0 0 0;
                    width: 100%;
                }

                .detail_swap_title {
                    height: 20px;
                    display: flex;
                    flex-direction: row;

                    .detail_swap_title_ico {
                        width: 20px;
                        height: 20px;
                        margin: -2px 6px 0 0;
                        background-size: cover;
                        background-image: url("../../../../../resources/ico-swap-ipc.svg");
                    }

                    .detail_swap_title_text {
                        line-height: 20px;
                    }
                }

                .detail_swap_rate {
                    margin: 10px 0 0 0;
                    height: 102px;
                    display: flex;
                    flex-direction: column;

                    .detail_swap_rate_title {
                        height: 16px;
                        line-height: 16px;
                    }

                    .detail_swap_rate_contents {
                        margin: 6px 0 0 0;
                        height: 60px;
                        border-radius: 2px;
                        background-color: rgba(#000, 0.3);

                        display: flex;
                        flex-direction: row;

                        .rate_ust {
                            width: 160px;
                            display: flex;
                            flex-direction: row;

                            @media (max-width: $mobile-size) {
                                width: 45%;
                            }

                            .rate_ust_ico {
                                width: 40px;
                                height: 40px;
                                margin: 10px 10px 0 20px;
                                background-size: cover;
                                background-image: url("../../../../../resources/Fanfury/logo-fury.svg");
                            }

                            .rate_ust_amount {
                                height: 40px;
                                line-height: 40px;
                                margin: 10px 0 0 0;
                            }
                        }

                        .rate_dummy {
                            width: 20px;
                            height: 20px;
                            margin: 20px 0 0 0;
                            background-size: cover;
                            background-image: url("../../../../../resources/ico-rate.svg");

                            @media (max-width: $mobile-size) {
                                margin: 20px 0 0 20px;
                            }
                        }

                        .rate_token {
                            width: 229px;
                            display: flex;
                            flex-direction: row;

                            .rate_token_ico {
                                width: 40px;
                                height: 40px;
                                margin: 10px 10px 0 20px;
                                background-size: cover;
                                background-image: url("../../../../../resources/logo-ust-ipc.png");

                                @media (max-width: $mobile-size) {
                                    margin: 10px 10px 0 20px;
                                }
                            }

                            .rate_token_amount {
                                height: 40px;
                                line-height: 40px;
                                margin: 10px 0 0 0;
                            }
                        }
                    }
                }

                .detail_swap_available {
                    // margin: 10px 0 0 0;
                    height: 41px;
                    display: flex;
                    flex-direction: column;

                    .detail_swap_available_wrap {
                        margin-top: 8px;
                        display: flex;
                        flex-direction: row;
                    }
                }
            }

            .detail_usevp {
                width: calc(50% - 20px);
                height: 190px;
                display: flex;
                flex-direction: column;

                @media (max-width: $mobile-size) {
                    margin: 40px 0 0 0;
                    width: 100%;
                }

                .detail_usevp_title {
                    height: 20px;
                    display: flex;
                    flex-direction: row;

                    .detail_usevp_title_ico {
                        width: 20px;
                        height: 20px;
                        margin: -2px 6px 0 0;
                        background-size: cover;
                        background-image: url("../../../../../resources/ico-vp.svg");
                    }

                    .detail_usevp_title_text {
                        line-height: 20px;
                    }
                }

                .detail_usevp_sub {
                    margin: 10px 0 0 0;
                    // height: 16px;
                    line-height: 16px;
                }

                .detail_usevp_contents {
                    height: 134px;
                    margin: 10px 0 0 0;
                    border-radius: 2px;
                    border: solid 1px rgba(255, 255, 255, 0.2);
                    background-color: rgba(255, 255, 255, 0.05);
                    display: flex;
                    flex-direction: column;

                    .usevp_titles {
                        height: 40px;
                        display: flex;
                        flex-direction: row;

                        .usevp_titles_available {
                            width: 50%;

                            .usevp_titles_available_text {
                                margin: 15px 0 0 30px;
                            }
                        }

                        .usevp_titles_when {
                            width: 50%;
                            display: flex;
                            flex-direction: row;
                            justify-content: right;

                            .usevp_titles_when_text {
                                margin: 15px 30px 0 0;
                            }
                        }
                    }

                    .usevp_contents {
                        height: 30px;
                        display: flex;
                        flex-direction: row;

                        @media (max-width: $mobile-size) {
                            justify-content: space-between;
                        }

                        .usevp_contents_available {
                            height: 20px;
                            width: 130px;
                            line-height: 20px;
                            margin: 5px 0 0 30px;

                            @media (max-width: $mobile-size) {
                                width: 40%;
                            }
                        }

                        .usevp_contents_arrow {
                            width: 20px;
                            height: 20px;
                            margin: 5px 0 0 20px;
                            background-size: cover;
                            background-image: url("../../../../../resources/ipc-next.svg");
                        }

                        .usevp_contents_when {
                            height: 20px;
                            width: 130px;
                            line-height: 20px;
                            margin: 5px 0 0 20px;
                            display: flex;
                            flex-direction: row;
                            justify-content: right;

                            @media (max-width: $mobile-size) {
                                width: 45%;
                                margin: 5px 30px 0 0;
                            }
                        }
                    }

                    .usevp_dummy {
                        height: 1px;
                        background: rgba(#fff, 0.2);
                    }

                    .usevp_public {
                        height: 93px;
                        display: flex;
                        flex-direction: row;

                        @media (max-width: $mobile-size) {
                            justify-content: space-between;
                        }

                        .usevp_public_wrap {
                            width: 126px;
                            height: 50px;
                            margin: 22px 0 0 30px;
                            display: flex;
                            flex-direction: column;

                            @media (max-width: $mobile-size) {
                                width: 40%;
                            }

                            .usevp_public_available {
                                width: 130px;
                                height: 20px;

                                line-height: 20px;
                                display: flex;
                                flex-direction: row;

                                @media (max-width: $mobile-size) {
                                    // width: 40%;
                                }

                                .usevp_public_available_maxamount {
                                    margin: 0 0 0 4px;
                                }
                            }
                        }

                        .usevp_public_arrow {
                            width: 20px;
                            height: 20px;
                            margin: 36px 0 0 20px;
                            background-size: cover;
                            background-image: url("../../../../../resources/ipc-next.svg");

                            @media (max-width: $mobile-size) {
                                margin: 36px 0 0 10px;
                            }
                        }

                        .usevp_public_when {
                            width: 130px;
                            height: 20px;
                            margin: 36px 0 0 20px;
                            line-height: 20px;
                            display: flex;
                            flex-direction: row;
                            justify-content: right;

                            @media (max-width: $mobile-size) {
                                width: 45%;
                                margin: 36px 30px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
