@import "mixins";
@import "variables";

.container {
    margin: 0 20px 21px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .text_label {
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
        letter-spacing: normal;
        margin-top: 20px;
        text-align: center;

        &.ipc_label {
            margin-top: 10px;
        }
    }
    .multiple_label {
        margin-top: -7px;
    }

    .icon_container {
        width: 80px;
        height: 80px;
        margin-top: 35px;

        .iconwrap {
            width: 80px;
            height: 80px;
            border-radius: 50px;
            border: solid 1px rgba(255, 255, 255, 0.4);
            background-color: #000;
            opacity: 0.5;
        }
        .icon {
            position: absolute;
            top: 45px;
            left: 50px;
            width: 60px;
            height: 60px;
            z-index: 2;
        }

    }
    

    .ipc {
        width: 120px;
        height: 127px;

        object-fit: contain;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}
