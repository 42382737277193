@import "mixins";
@import "variables";

.wrap {
    width: 100%;
    position: relative;
    z-index: 0;

    &.hash {
        background: transparent;
    }

    @media (max-width: $width-pc1) {
        height: calc(100%);
    }

    .container {
        // @include container1();
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        @media (max-width: $width-pc1) {
            width: calc(100% - 60px);
        }

        .title {
            display: flex;
            margin: 40px 0 20px 0;
            flex-direction: row;

            .docs_button {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;

                text-decoration: none;
                transition-duration: $anim-duration;
                margin: 0 0 0 10px;

                // @media (max-width: $mobile-size) {
                //     margin: 7px 0 0 0;
                // }

                .icon {
                    width: 13px;
                    height: 13px;
                    transition-duration: $anim-duration;
                    background-size: cover;
                    background-image: url("../../resources/ico-exportlink2.svg");
                }

                .title {
                    @include font(14px, rgba(white, 0.3));
                    transition-duration: $anim-duration;
                    text-decoration: underline;
                    margin: 0 0 0 4px;
                }

                &:hover {
                    .icon {
                        background-image: url("../../resources/ico-exportlink.svg");
                    }

                    .title {
                        color: #0661b9;
                    }
                }
            }
        }
    }

    .header {
        @include box_shadow();
        margin-bottom: 40px;
    }

    // .content {
    // @include box_shadow();

    // width: calc(100% - 60px);
    // padding-top: 50px;
    // padding-left: 30px;
    // padding-right: 30px;
    // width: 100%;

    .trade_inner_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        // width: calc(100% - 60px);

        // padding-top: 60px;

        .from {
            // background: red;
            height: 74px;
        }

        .arrow {
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: url("../../resources/ipc-next2.svg");
            margin: 22px auto 0 auto;

            &.error {
                background: transparent;
            }
        }

        .to {
            height: 74px;
        }

        .spreaddummy {
            width: 100%;
            height: 1px;
            background: rgba(#fff, 0.3);
        }

        .createbutton {
            width: 100%;
            height: 48px;
            margin: 30px auto 33px auto;

            .button {
                border-radius: 24px;
                width: 100%;
                background-color: rgba(#fff, 0.1);
                @include fontBold(16px, rgba(255, 255, 255, 0.3));

                display: flex;
                flex-direction: row;
                justify-content: center;
                line-height: 48px;
                cursor: not-allowed;

                &:hover {
                    border: solid 1px rgba(#fff, 0.1);
                    background-color: rgba(#000, 0.1);
                    width: calc(100% - 2px);
                    height: 46px;
                    line-height: 46px;
                }

                &.enable {
                    border-radius: 24px;
                    background-color: #425ad0;
                    @include fontBold(16px, white);
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(#425ad0, 0.6);
                        border: solid 1px rgba(#425ad0, 0.6);
                        color: white;
                        width: calc(100% - 2px);
                        height: 46px;
                        line-height: 46px;
                    }
                }

                &.enable2 {
                    border-radius: 24px;
                    background-color: #bd3552;
                    @include fontBold(16px, white);
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(#bd3552, 0.6);
                        border: solid 1px rgba(#bd3552, 0.6);
                        color: white;
                        width: calc(100% - 2px);
                        height: 46px;
                        line-height: 46px;
                    }
                }
            }
        }

        .trade_exchangeicon {
            height: 30px;
        }
    }
    // }

    .trade_container {
        width: 100%;
        // height: 715px;
        display: flex;
        flex-direction: row;

        .charttab {
            width: 50%;
            border-radius: 2px 0 0 2px;
            border-top: solid 1px rgba(255, 255, 255, 0.2);
            border-left: solid 1px rgba(255, 255, 255, 0.2);
            border-bottom: solid 1px rgba(255, 255, 255, 0.2);
            background-color: rgba(255, 255, 255, 0.05);

            @media (max-width: $width-pc1) {
                display: none;
            }
        }

        .tradetab {
            width: 50%;
            border-radius: 0 2px 2px 0;
            border: solid 1px rgba(255, 255, 255, 0.2);
            background-image: linear-gradient(320deg, #23293b, #39425d);
            display: flex;
            flex-direction: column;

            @media (max-width: $width-pc1) {
                width: 100%;
            }

            .tradetab_top {
                width: 100%;
                height: 58px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .tradedummy {
                width: 100%;
                height: 1px;
                background: #000;
                opacity: 0.1;
            }

            .tradetab_bottom {
                margin: 20px auto 0 auto;
                width: calc(100% - 40px);
            }
        }
    }
}
