@import "mixins";
@import "variables";

@keyframes rotate {
    0% {
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(-10deg);
    }
}

.container {
    @include container1();
    align-items: flex-start;

    .section0_container {
        @include box_shadow();

        width: 100%;

        display: flex;
        flex-direction: row;

        @media (max-width: $mobile-size) {
            flex-direction: column;
        }
    }

    .row1_container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;

        .row1 {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media (max-width: $mobile-size) {
                flex-direction: column;
                flex: none;
                width: 100%;
                justify-content: flex-start;
            }
            .section1_container {
                flex: 1;
                width: 100%;
            }
            .section2_container {
                flex: 1;
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-left: 20px;

                @media (max-width: $mobile-size) {
                    margin-left: 0px;
                }
            }
        }
    }

    .row2_container {
        display: flex;
        flex-direction: row;
        width: 100%;

        @media (max-width: $mobile-size) {
            flex-direction: column;
        }
    }
}
