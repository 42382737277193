@import "mixins";
@import "variables";

.campaigns_item {
    @include box_shadow();
    cursor: pointer;

    margin-bottom: 40px;
    text-decoration: none;

    width: calc(50% - 22px);
    float: left;
    background-size: cover;
    border-radius: 2px;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(294deg, #23293b, #39425d);
    display: flex;
    flex-direction: row;
    position: relative;
    border: solid 0.5px rgba(255, 255, 255, 0.2);

    .campaigns_itemwrap {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        border-radius: 2px;
    }

    &.activefalse {
        background-image: linear-gradient(
            294deg,
            rgba(#23293b, 0.4),
            rgba(#39425d, 0.4)
        );

        &:hover {
            border: solid 0.5px #ffe646;
            box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
            background-image: linear-gradient(
                315deg,
                rgba(#191b1e, 0.4),
                rgba(#1b1f2c, 0.4)
            );
        }
    }

    &:hover {
        border: solid 0.5px #ffe646;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
        background-image: linear-gradient(315deg, #191b1e, #1b1f2c);
    }

    @media (max-width: $mobile-size) {
        width: calc(100%);
        flex-direction: column;
    }

    .cardlogo {
        width: 181px;
        height: 100%;

        @media (max-width: $mobile-size) {
            width: calc(100%);
        }
    }

    .carddummy2 {
        width: 1.1px;
        height: 100%;
        // background: rgba(#000, 0.7);
        background: rgba(#000, 0.1);

        @media (max-width: $mobile-size) {
            width: calc(100%);
            height: 1px;
        }
    }

    .carddummy {
        width: 1px;
        height: 100%;
        background: rgba(#fff, 0.1);

        @media (max-width: $mobile-size) {
            width: calc(100%);
            height: 1px;
        }
    }

    .cardcontent {
        width: 398px;
        height: 100%;

        @media (max-width: $mobile-size) {
            width: calc(100%);
        }
    }

    .row2 {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        @media (max-width: $mobile-size) {
            margin-top: 55px;
            flex-direction: column;
        }

        .box {
            flex: 1;

            border-radius: 10px;
            background-color: #222226;
            overflow: hidden;

            @media (max-width: $mobile-size) {
                width: 100%;
            }

            .box2 {
                height: 67px;

                box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
                background-color: #141418;

                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 20px;
                padding-right: 20px;

                @media (max-width: $mobile-size) {
                    height: auto;
                    flex-direction: column;
                    padding-top: 12px;
                    padding-bottom: 12px;
                }

                .text1 {
                    @media (max-width: $mobile-size) {
                        align-self: flex-start;
                    }
                }
                .text3 {
                    @media (max-width: $mobile-size) {
                        align-self: flex-start;
                    }
                }

                .text2 {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    @media (max-width: $mobile-size) {
                        align-self: flex-start;
                    }
                }

                .boxdummy {
                    flex: 1;

                    @media (max-width: $mobile-size) {
                        flex: none;
                        height: 8px;
                    }
                }
            }
        }

        .dummy {
            width: 30px;

            @media (max-width: $mobile-size) {
                height: 20px;
            }
        }
    }
}

.cardlogo_container {
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        flex-direction: row;
    }

    .cardlogo_img {
        margin: 40px auto 0 auto;

        .ipc_cardlogo {
            width: 120px;
            height: 127px;
            background-size: cover;
            background-image: url("../../../resources/logod-arts-main2.svg");
            position: relative;
            &.earlybird {
                background-image: url("../../../resources/logod-arts-main.png");
            }

            .activefalse {
                width: 80px;
                height: 80px;
                border-radius: 50px;
                border: solid 1px rgba(255, 255, 255, 0.2);
                background-color: rgba(#000, 0.5);
                position: absolute;
                @include fontBold(12px, #fff);
                text-align: center;
                left: 18px;

                &.pylon {
                    background: rgb(14, 39, 67);
                }

                .closed {
                    margin: 37px 0 0 0;

                    @media (max-width: $mobile-size) {
                        margin: 28px 0 0 0;
                    }
                }

                &.activefalse {
                    background-color: rgba(#000, 0.8);
                }

                @media (max-width: $mobile-size) {
                    width: 60px;
                    height: 60px;
                    left: 0;
                    @include fontBold(10px, #fff);
                }
            }

            @media (max-width: $mobile-size) {
                margin: 0 0 0 0;
                width: 57px;
                height: 60px;
            }
        }

        @media (max-width: $mobile-size) {
            margin: 20px 0 20px 20px;
        }
    }

    .cardlogo_mobile {
        .cardlogo_symbol {
            margin: 10px auto 0 auto;
            @include fontBold(20px, #fff);
            text-align: center;

            @media (max-width: $mobile-size) {
                width: 100%;
                text-align: left;

                margin: 30px 0 0 20px;
            }

            &.activefalse {
                opacity: 0.3;
            }

            &.hovercheck {
                opacity: 1;
            }
        }

        .cardlogo_name {
            width: 120px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            margin: 2px auto 0 auto;
            @include font(12px, #fff);

            @media (max-width: $mobile-size) {
                width: 100%;
                margin: 2px 0 0 20px;
                text-align: left;
            }

            &.activefalse {
                opacity: 0.3;
            }

            &.hovercheck {
                opacity: 1;
            }
        }
    }
}

.cardcontent_container {
    width: calc(100% - 60px);
    height: calc(100% - 80px);
    margin: 40px auto 0 auto;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        margin: 20px auto 0 auto;
        height: 100%;
        width: calc(100% - 40px);
    }

    .cardcontent_title {
        height: 52px;
        width: 100%;
        @include font(18px, #fff);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: -0.3px;
        word-break: break-all;
        margin: 0 0 20px 0;

        @media (max-width: $mobile-size) {
            height: auto;
        }

        &.activefalse {
            opacity: 0.3;
        }

        &.hovercheck {
            opacity: 1;
        }
    }

    .cardcontent_distribute {
        width: 100%;
        height: 54px;
        margin: 18px 0 0 0;
        display: flex;
        flex-direction: column;

        @media (max-width: $mobile-size) {
            margin: 0 0 30px 0;
        }

        .distributetitle {
            width: 100%;
            height: 20px;
            line-height: 20px;
        }

        .distributedetail {
            width: 100%;
            height: 30px;
            margin: 4px 0 0 0;
            display: flex;
            flex-direction: column;

            .distributedetail_amounts {
                height: 16px;
                line-height: 16px;
                width: 100%;
                display: flex;
                flex-direction: row;

                .amounts_now {
                    width: 50%;
                    height: 16px;
                }

                .amounts_max {
                    width: 50%;
                    height: 16px;
                    // display: flex;
                    // flex-direction: row;
                    // justify-content: right;
                    position: relative;

                    .amounts_max_text {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }

            .distributedetail_bar {
                margin: 6px 0 0 0;
                height: 6px;
                width: 100%;
                border-radius: 8px;
                background-color: rgba(#fff, 0.1);
                position: relative;

                .distributedetail_bar_now {
                    position: absolute;
                    height: 6px;
                    top: 0;
                    left: 0;
                    background-color: #f29077;
                    border-radius: 8px 0 0 8px;
                }
            }
        }
    }

    .cardcontent_reward {
        width: 100%;
        height: 54px;
        margin: 18px 0 0 0;
        display: flex;
        flex-direction: column;

        @media (max-width: $mobile-size) {
            margin: 0 0 56px 0;
        }

        .rewardstitle {
            width: 100%;
            height: 20px;
            line-height: 20px;
        }

        .rewardsdetail {
            width: 100%;
            height: 30px;
            margin: 4px 0 0 0;
            border-radius: 4px;
            background-color: #202536;
            display: flex;
            flex-direction: row;

            @media (max-width: $mobile-size) {
                flex-direction: column;
                height: 75px;
            }

            &.activefalse {
                // opacity: 0.3;
            }

            &.hovercheck {
                opacity: 1;
            }

            .rewardsdetail_amount {
                height: 100%;
                // width: 169px;
                display: flex;
                flex-direction: row;

                .amount_ico {
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../../resources/ico-rewards-01.svg");
                    margin: 5px 10px 0 10px;

                    &.activefalse {
                        opacity: 0.3;
                    }

                    &.hovercheck {
                        opacity: 1;
                    }
                }

                .amount_reward {
                    // width: 120px;
                    height: 20px;
                    margin: 6px 8px 0 0;

                    .amount_reward_pamount {
                        line-height: 20px;

                        .pamount {
                            .pamount_pc {
                                display: block;

                                @media (max-width: $mobile-size2) {
                                    display: none;
                                }

                                @media (max-width: $mobile-size) {
                                    display: block;
                                }
                            }

                            .pamount_middle {
                                display: none;

                                @media (max-width: $mobile-size2) {
                                    display: block;
                                }

                                @media (max-width: $mobile-size) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .rewardsdetail_dummy {
                height: 14px;
                width: 1px;
                background: rgba(#fff, 0.2);
                margin: 8px 0 0 0;

                @media (max-width: $mobile-size) {
                    height: 1px;
                    width: calc(100% - 20px);
                    margin: 8px auto 0 auto;
                }
            }

            .rewardsdetail_referral {
                height: 100%;
                // width: 168px;
                display: flex;
                flex-direction: row;

                @media (max-width: $mobile-size) {
                    width: 100%;
                    margin: 0 0 10px 0;
                }

                .referral_ico {
                    width: 20px;
                    height: 20px;
                    margin: 5px 10px 0 10px;
                    background-size: cover;
                    background-image: url("../../../resources/ico-rewards-02.svg");

                    @media (max-width: $mobile-size2) {
                        margin: 5px 10px 0 6px;
                    }

                    @media (max-width: $mobile-size) {
                        margin: 5px 10px 0 10px;
                    }

                    &.activefalse {
                        opacity: 0.3;
                    }

                    &.hovercheck {
                        opacity: 1;
                    }
                }

                .referral_reward {
                    // width: 140px;
                    height: 20px;
                    margin: 6px 0 0 0;
                    line-height: 20px;
                    display: flex;
                    flex-direction: row;

                    .referral_reward_first {
                        .referrals {
                            .referrals_pc {
                                display: block;

                                @media (max-width: $mobile-size2) {
                                    display: none;
                                }

                                @media (max-width: $mobile-size) {
                                    display: block;
                                }
                            }

                            .referrals_middle {
                                display: none;

                                @media (max-width: $mobile-size2) {
                                    display: block;
                                }

                                @media (max-width: $mobile-size) {
                                    display: none;
                                }
                            }
                        }
                    }

                    .referral_reward_second {
                        margin: 0 0 0 3px;
                        line-height: 22px;

                        .referrals {
                            display: block;

                            @media (max-width: $mobile-size2) {
                                margin: -1px 0 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .cardcontent_button {
        height: 36px;
        width: 100%;
        margin: 20px 0 10px 0;
    }
}
