@import "mixins";
@import "variables";

.container {
    width: 100%;
    margin-top: 40px;

    .reward_total_header {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 20px 0px;
        margin-top: 20px;

        border-radius: 2px;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(275deg, #23293b, #39425d);

        @media (max-width: $mobile-size) {
            flex-direction: column;
            align-items: flex-start;
        }

        .total_reward {
            padding-left: 30px;
            flex: 1;

            @media (max-width: $mobile-size) {
                padding-left: 20px;
            }
        }
        .line_dummy {
            height: 20px;
            width: 1px;
            background-color: rgba(255, 255, 255, 0.3);
            @media (max-width: $mobile-size) {
                display: none;
            }
        }
        .line_dummy2 {
            display: none;

            @media (max-width: $mobile-size) {
                display: block;
                height: 1px;
                width: calc(100% - 40px);
                align-self: center;
                margin-top: 20px;
                margin-bottom: 20px;
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
        .total_participate {
            padding-left: 30px;
            flex: 1;
            @media (max-width: $mobile-size) {
                padding-left: 20px;
            }
        }
    }
    .reward_value_header {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 20px 0px;

        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.05);

        margin-top: -1px;
        border-top: transparent;

        @media (max-width: $mobile-size) {
            flex-direction: column;
            align-items: flex-start;
        }

        .claimed {
            flex: 1;
            padding-left: 30px;
            @media (max-width: $mobile-size) {
                padding-left: 20px;
            }
        }
        .locked {
            flex: 1;
            padding-left: 30px;
            display: flex;
            flex-direction: column;

            @media (max-width: $mobile-size) {
                padding-left: 20px;
            }
            .title {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 6px;
                .icon {
                    margin-bottom: 3px;
                    width: 20px;
                    height: 20px;
                    background-image: url(../../resources/ico-feelock01.svg);
                    object-fit: contain;
                    opacity: 0.5;
                    margin-top: -2px;
                }
            }
        }
        .claimable {
            flex: 1;
            padding-left: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            
            @media (max-width: $mobile-size) {
                width: calc(100% - 30px);
                flex-direction: column;
                align-items: flex-start;
                padding-left: 20px;
            }

            .text {
                display: flex;
                flex-direction: column;
            }
            .button {
                width: 100px;
                height: 30px;
                margin-right: 30px;
                @media (max-width: $mobile-size) {
                    align-self: flex-end;
                }
            }
        }

        .line_dummy {
            height: 20px;
            width: 1px;
            background-color: rgba(255, 255, 255, 0.3);
            @media (max-width: $mobile-size) {
                display: none;
            }
        }
        .line_dummy2 {
            display: none;

            @media (max-width: $mobile-size) {
                display: block;
                height: 1px;
                width: calc(100% - 40px);
                align-self: center;
                margin-top: 20px;
                margin-bottom: 20px;
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
}
