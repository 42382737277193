@import "mixins";
@import "variables";

.list_container {
    border-radius: 2px;
    height: 230px;

    .slider_background {
        position: absolute;
        width: 46px;
        height: 230px;
        border-radius: 2px;
        z-index: 1;
        &.left {
            left: 0;
            background-image: linear-gradient(
                to right,
                #222630,
                rgba(34, 38, 48, 0)
            );
        }
        &.right {
            right: 0;
            background-image: linear-gradient(
                to left,
                #222630,
                rgba(34, 38, 48, 0)
            );
        }
    }

    .slider_arrow {
        position: absolute;
        width: 40px;
        height: 40px;
        z-index: 2;
        background-color: #000000;
        border-radius: 20px;
        opacity: 0.5;
        top: 40%;

        &.left {
            left: 10px;
            .slider_arrow_icon {
                width: 40px;
                height: 40px;
                &.on:hover {
                    background-color: #ffe646;
                    border-radius: 20px;
                    background-image: url(../../../resources/ico-arrow-coming-over-2.svg);
                }
                &.on {
                    background-image: url(../../../resources/ico-arrow-coming-on-2.svg);
                }
                &.off {
                    background-image: url(../../../resources/ico-arrow-coming-off-2.svg);
                }
            }
            @media (max-width: $mobile-size) {
                left: 20px;

                &.on:active {
                    background-color: #ffe646;
                    border-radius: 20px;
                    background-image: url(../../../resources/ico-arrow-coming-over-2.svg);
                }
            }
        }
        &.right {
            right: 10px;
            .slider_arrow_icon {
                width: 40px;
                height: 40px;
                &.on:hover {
                    background-color: #ffe646;
                    border-radius: 20px;
                    background-image: url(../../../resources/ico-arrow-coming-over.svg);
                }
                &.on {
                    background-image: url(../../../resources/ico-arrow-coming-on.svg);
                }
                &.off {
                    background-image: url(../../../resources/ico-arrow-coming-off.svg);
                }
            }
            @media (max-width: $mobile-size) {
                right: 20px;
                &.on:hover {
                    background-color: #ffe646;
                    border-radius: 20px;
                    background-image: url(../../../resources/ico-arrow-coming-over.svg);
                }
            }
        }

        .arrow_background {
            width: 40px;
            height: 40px;
            opacity: 0.3;
            border-radius: 20px;
            background-color: #64646e;
        }
    }
    .slider_wrapper {
        position: absolute;

        .slider_ul {
            list-style: none;

            .slider_container {
                height: 100%;
                overflow: hidden;
                transition: 0.3s ease-in-out;
                -webkit-transition: 0.3s ease-in-out;

                .slider_li {
                    float: left;
                    padding-bottom: 10px;

                    .card_container {
                        height: 100%;
                        width: calc(160px + 50px);
                        position: relative;
                        display: flex;
                        margin-top: 15px;

                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                    }
                }

                .card_item_color {
                    position: absolute;
                    width: 160px;
                    height: 170px;
                    border-radius: 2px;

                    &.default_card {
                        background-image: linear-gradient(
                            to bottom,
                            #39425d,
                            rgba(35, 41, 59, 0.4)
                        );
                    }
                    &.fanfury_card {
                        opacity: 0.5;
                        background-image: linear-gradient(
                                to bottom,
                                #5a3fe1,
                                rgba(90, 63, 225, 0.4)
                            ),
                            linear-gradient(to bottom, #000, #000);
                    }
                    &.talis_card {
                        opacity: 0.5;
                        background-image: linear-gradient(
                                to bottom,
                                #13ea5b,
                                #093e0b
                            ),
                            linear-gradient(to bottom, #000, #000);
                    }
                    &.lunart_card {
                        opacity: 0.5;
                        background-image: linear-gradient(
                                to bottom,
                                #f29077,
                                rgba(242, 144, 119, 0.4)
                            ),
                            linear-gradient(to bottom, #000, #000);
                    }
                }

                .card_item {
                    position: relative;
                    width: 160px;
                    border-radius: 2px;
                    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
                    height: 170px;

                    .card_link {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        .link_container {
                            position: absolute;
                            top: 0px;
                            right: 10px;
                            width: 30px;
                            height: 30px;
                            margin-top: 10px;

                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .link_background {
                                position: absolute;
                                top: 0px;
                                width: 30px;
                                height: 30px;
                                border-radius: 50px;
                                border: solid 1px rgba(255, 255, 255, 0.2);
                                background-color: #000;
                                opacity: 0.5;
                            }
                            .hover_link_button {
                                width: 13px;
                                height: 13px;
                                margin-top: 2px;
                                z-index: 2;

                                background-size: cover;
                                background-image: url("../../../resources/ico-exportlink5.svg");
                            }
                            .unhover_link_button {
                                width: 13px;
                                height: 13px;
                                margin-top: 2px;
                                z-index: 2;

                                background-size: cover;
                                background-image: url("../../../resources/ico-exportlink2.svg");
                            }
                        }
                    }
                }
            }
        }
    }
}
