@import "mixins";
@import "variables";

.container {
    width: 250px;
    height: 400px;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        width: calc(100%);
        margin: 0;
    }

    .header_mysharing_title {
        width: 100%;
        height: 24px;
        line-height: 24px;
        margin: 0 0 20px 0;
    }

    .header_mysharing_wrap {
        width: 100%;
        // height: calc(100% + 300px);

        background-color: rgba(0, 0, 0, 0.2);
        position: relative;
        z-index: 2;

        @media (max-width: $mobile-size) {
            // height: calc(100%);
        }

        .header_mysharing_bg_footer {
            top: 0;
            left: 0px;
            width: calc(100% + 2px);
            border-radius: 2px;
            height: 100%;
            position: absolute;
            background-image: linear-gradient(
                240deg,
                rgba(#13ea5b, 0.5),
                rgba(242, 144, 119, 0)
            );
            z-index: -1;
        }

        .header_mysharing_contents {
            width: 100%;
            height: 296px;
            border-radius: 2px;
            border: solid 1px rgba(255, 255, 255, 0.3);
            box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
            display: flex;
            flex-direction: column;

            @media (max-width: $mobile-size) {
                height: 310px;
            }

            .header_mysharing_link {
                width: 100%;
                height: 180px;
                display: flex;
                flex-direction: column;

                @media (max-width: $mobile-size) {
                    margin: 0 0 20px 0;
                }

                .link_ico {
                    width: 60px;
                    height: 60px;
                    background-size: cover;
                    background-image: url("../../../../resources/ico-sharing-link.svg");
                    margin: 30px auto 0 auto;
                }

                .link_title {
                    width: 125px;
                    height: 20px;
                    margin: 10px auto 0 auto;
                    display: flex;
                    flex-direction: row;

                    @media (max-width: $mobile-size) {
                        margin: 27px auto 0 auto;
                    }

                    .link_title_name {
                        line-height: 22px;
                    }

                    .link_title_ico {
                        width: 20px;
                        height: 20px;
                        background-size: cover;
                        margin: 0 0 0 6px;
                        background-image: url("../../../../resources/ico-link-tooltip.svg");
                    }
                }

                .link_button {
                    width: 120px;
                    height: 30px;
                    margin: 10px auto 0 auto;
                }
            }

            .header_mysharing_dummy {
                width: 250px;
                height: 1px;
                opacity: 0.2;
                background-color: #fff;

                @media (max-width: $mobile-size) {
                    width: calc(100%);
                }
            }

            .header_mysharing_cap {
                width: 100%;
                height: 116px;
                display: flex;
                flex-direction: column;

                .mysharing_rewardcap {
                    width: calc(100% - 60px);
                    height: 20px;
                    margin: 20px auto 0 auto;
                    line-height: 20px;
                }

                .mysharing_max {
                    width: calc(100% - 60px);
                    height: 15px;
                    margin: 1px 0 0 30px;
                    line-height: 15px;
                    cursor: pointer;
                }

                .mysharing_percentage {
                    width: calc(100% - 60px);
                    height: 23px;
                    margin: 8px auto 0 auto;
                    border-radius: 2px;
                    // background-color: #2e2b29;

                    position: relative;
                    overflow: hidden;

                    .bar_title {
                        height: 12px;
                        width: 100%;
                        line-height: 12px;

                        .barover {
                            display: flex;
                            flex-direction: row;
                            margin: -4px 0 0 0;

                            .barover_ico {
                                width: 20px;
                                height: 20px;
                                background-size: cover;
                                margin: 0 6px 0 0;
                                background-image: url("../../../../resources/ico-warning01.svg");
                            }
                        }
                    }

                    .defaultbar {
                        position: absolute;
                        left: 0px;
                        top: 17px;
                        height: 6px;
                        width: 100%;
                        border-radius: 8px;
                        background-color: rgba(255, 255, 255, 0.5);
                    }

                    .bar {
                        position: absolute;
                        left: 0px;
                        top: 17px;
                        height: 6px;
                        width: 100%;
                        border-radius: 8px;
                        background-color: #ffe646;
                    }
                }
            }
        }
    }
}
