@import "mixins";
@import "variables";

.container {
    // margin-top: 40px;
    width: 100%;

    display: flex;
    flex-direction: row;
    flex: 1;

    @media (max-width: $mobile-size) {
        flex-direction: column;
    }

    .inner_container {
        @include box_shadow();
        flex: 1;
        padding: 30px;
        display: flex;
        flex-direction: column;
        border-radius: 2px;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(302deg, #23293b, #39425d);

        @media (max-width: $mobile-size) {
            padding: 20px;
        }

        .chart {
            display: flex;
            flex-direction: column;

            .info_container {
                display: flex;
                flex-direction: column;

                @media (max-width: $mobile-size) {
                    flex-direction: column;
                    margin-bottom: 20px;
                }
                .info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .info_title {
                        display: flex;
                        flex-direction: row;
                        .icon {
                            width: 13px;
                            height: 13px;
                            border-radius: 4px;
                        }
                    }
                }

                .spacer {
                    width: 4px;
                    @media (max-width: $mobile-size) {
                        flex: 1;
                    }
                }

                .infodummy {
                    width: 30px;
                    height: 10px;
                }
            }

            .bar {
                margin-top: 10px;

                height: 4px;
                border-radius: 2px;

                overflow: hidden;

                display: flex;
                flex-direction: row;
            }
        }
    }

    .dummy {
        width: 40px;
        @media (max-width: $mobile-size) {
            height: 40px;
        }
    }
}
