@import "mixins";
@import "variables";

.wrap {
    width: 100%;
    position: relative;
    z-index: 0;

    &.hash {
        background: transparent;
    }

    @media (max-width: $mobile-size) {
        height: calc(100%);
    }

    .container {
        width: 800px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        @media (max-width: $mobile-size) {
            width: calc(100% - 56px);
        }

        .campaign_container {
            margin-top: 40px;
            display: flex;
            flex-direction: column;

            .before {
                background-color: rgba(white, 0.03);
                height: 230px;

                border-radius: 2px;
                background-size: cover;
                background-image: url("../../resources/img-v-2.svg");

                @media (max-width: $width-pc2) {
                    height: 389px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    background-image: url("../../resources/img-mv-2.svg");
                }

                @media (max-width: 390px) {
                    height: 310px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    background-image: url("../../resources/img-mv-2.svg");
                }
            }

            .after {
                border-radius: 2px;
                padding: 30px 30px 40px 30px;
                box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);
                border: solid 1px rgba(255, 255, 255, 0.2);
                background-image: linear-gradient(293deg, #23293b, #39425d);

                display: flex;
                flex-direction: column;
            }

            .referrerbox {
                margin-top: -10px;
                min-height: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .reficon {
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../resources/ico-referrallink-x.svg");

                    &.checked {
                        background-image: url("../../resources/ico-referrallink-o.svg");
                    }
                }

                .text {
                    @include fontLight(12px, white);
                    margin-left: 6px;
                }
            }

            .dummy1 {
                margin-top: 30px;
                display: flex;
            }

            .button {
                width: 400px;
                height: 48px;
                align-self: center;
                margin-top: 30px;
            }
        }

        .title {
            display: flex;
            margin: 40px 0 20px 0;
            flex-direction: row;

            @media (max-width: $mobile-size) {
                flex-direction: column;
            }

            .docs_button {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;

                text-decoration: none;
                transition-duration: $anim-duration;
                margin: 0 0 0 10px;

                @media (max-width: $mobile-size) {
                    margin: 7px 0 0 0;
                }

                .icon {
                    width: 13px;
                    height: 13px;
                    transition-duration: $anim-duration;
                    background-size: cover;
                    background-image: url("../../resources/ico-exportlink2.svg");
                }

                .title {
                    @include font(14px, rgba(white, 0.3));
                    transition-duration: $anim-duration;
                    text-decoration: underline;
                    margin: 0 0 0 4px;
                }

                &:hover {
                    .icon {
                        background-image: url("../../resources/ico-exportlink.svg");
                    }

                    .title {
                        color: #0661b9;
                    }
                }
            }
        }

        .mtitle {
            display: none;

            @media (max-width: $mobile-size) {
                display: block;
            }
        }

        .vp_container {
            display: flex;
            flex-direction: column;
            .inner_container {
                // height: 616px;
                display: flex;
                flex-direction: row;

                @media (max-width: $mobile-size) {
                    flex-direction: column;
                }

                .myvp {
                    width: 280px;
                    border-radius: 2px 0 0 2px;
                    background-color: rgba(255, 255, 255, 0.05);
                    display: flex;
                    flex-direction: column;

                    @media (max-width: $mobile-size) {
                        width: 100%;
                        border-radius: 2px 2px 0 0;
                    }
                }

                .changevp {
                    width: 520px;
                    border-radius: 0 2px 2px 0;
                    border: solid 1px rgba(255, 255, 255, 0.2);
                    background-image: linear-gradient(322deg, #23293b, #39425d);
                    display: flex;
                    flex-direction: column;

                    @media (max-width: $mobile-size) {
                        width: calc(100% - 2px);
                        border-radius: 0 0 2px 2px;
                    }

                    .vpexchange {
                        width: calc(100% - 60px);
                        height: 190px;
                        margin: 30px auto 0 auto;

                        @media (max-width: $mobile-size) {
                            width: calc(100% - 40px);
                        }
                    }

                    .vpinfo {
                        // height: 245px;
                        width: calc(100% - 60px);
                        margin: 40px auto 0 auto;
                        display: flex;
                        flex-direction: column;

                        @media (max-width: $mobile-size) {
                            width: calc(100% - 40px);
                        }

                        .price_txfee {
                            border-radius: 5px 5px 0 0;
                            border: solid 1px #fff;
                            background-color: rgba(255, 255, 255, 0.1);
                            height: 60px;
                            display: flex;
                            flex-direction: column;

                            .txfee {
                                display: flex;
                                flex-direction: row;
                                height: 20px;
                                width: calc(100% - 40px);
                                margin: 20px auto 0 auto;
                                justify-content: space-between;

                                .txfee_title {
                                    width: 85px;
                                    display: flex;
                                    flex-direction: row;

                                    .txfee_title_ico {
                                        width: 20px;
                                        height: 20px;
                                        background-size: cover;
                                        background-image: url("../../resources/vp-check.svg");
                                        margin: 0 8px 0 0;
                                    }

                                    .txfee_title_text {
                                        @include fontLight(16px, #fff);
                                        line-height: 20px;
                                    }
                                }

                                .txfee_amount {
                                    width: 120px;
                                    position: relative;

                                    .txfee_amount_wrap {
                                        display: flex;
                                        flex-direction: row;
                                        position: absolute;
                                        right: 0;

                                        .txfee_amount_text {
                                            line-height: 20px;
                                        }

                                        .txfee_amount_symbol {
                                            margin: 5px 0 0 4px;
                                        }
                                    }
                                }
                            }
                        }

                        .vpalert {
                            border-radius: 5px;
                            border-right: solid 1px rgba(#fff, 0.3);
                            border-left: solid 1px rgba(#fff, 0.3);
                            border-bottom: solid 1px rgba(#fff, 0.3);
                            border-radius: 0 0 5px 5px;
                            display: flex;
                            flex-direction: column;

                            .vpalert_wrap {
                                width: calc(100% - 40px);
                                margin: 20px auto 0 auto;
                            }

                            .vpalert_checkbutton {
                                width: calc(100% - 30px);
                                height: 30px;
                                margin: 15px auto 15px auto;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-end;
                            }
                        }
                    }

                    .createbutton {
                        width: calc(100% - 60px);
                        height: 48px;
                        margin: 30px auto 40px auto;

                        @media (max-width: $mobile-size) {
                            width: calc(100% - 40px);
                        }

                        .button {
                            border-radius: 24px;
                            width: 100%;
                            background-color: rgba(#fff, 0.1);
                            @include fontBold(16px, rgba(255, 255, 255, 0.3));

                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            line-height: 48px;
                            cursor: not-allowed;

                            &:hover {
                                border: solid 1px rgba(#fff, 0.1);
                                background-color: rgba(#000, 0.1);
                                width: calc(100% - 2px);
                                height: 46px;
                                line-height: 46px;
                            }

                            &.enable {
                                border-radius: 24px;
                                background-color: #ffe646;
                                @include fontBold(16px, black);
                                cursor: pointer;

                                &:hover {
                                    background-color: rgba(#ffe646, 0.6);
                                    border: solid 1px rgba(#ffe646, 0.6);
                                    color: black;
                                    width: calc(100% - 2px);
                                    height: 46px;
                                    line-height: 46px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .vpcard {
            width: 504px;
            height: 334px;
            background-size: cover;
            background-image: url("../../resources/vp-card.png");
            position: relative;
            margin-left: -30px;
            margin-right: -30px;
            align-self: center;

            @media (max-width: $mobile-size) {
                width: 334px;
                height: 504px;
                margin-top: -30px;

                background-image: url("../../resources/vp-m-card.png");
            }

            @media (max-width: $mobile-min-size) {
                width: 225px;
                height: 340px;
                margin-top: -30px;

                background-image: url("../../resources/vp-m-card.png");
            }

            .burn {
                position: absolute;
                width: 44px;
                height: 44px;
                bottom: 67px;
                left: 80px;
                background-size: cover;
                background-image: url("../../resources/gif_burn.gif");

                @media (max-width: $mobile-size) {
                    top: 361px;
                    left: 62px;
                }

                @media (max-width: $mobile-min-size) {
                    width: 29px;
                    height: 29px;
                    top: 244px;
                    left: 42px;
                }
            }

            .vpbalance {
                position: absolute;
                top: 147.5px;
                right: 115px;
                height: 37px;
                display: flex;
                flex-direction: row;
                align-items: center;

                @media (max-width: $mobile-size) {
                    width: calc(100% - 114px);
                    border-radius: 4.5px;
                    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.27);
                    border: solid 0.9px #515157;
                    top: 225px;
                    left: 57px;
                }

                @media (max-width: $mobile-min-size) {
                    width: calc(100% - 80px);
                    border-radius: 4.5px;
                    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.27);
                    border: solid 0.9px #515157;
                    top: 146px;
                    left: 40px;
                }

                .vpamount {
                    .vpamountpc {
                        display: block;

                        @media (max-width: $mobile-min-size) {
                            display: none;
                        }
                    }

                    .vpamountmobile {
                        display: none;

                        @media (max-width: $mobile-min-size) {
                            display: block;
                        }
                    }
                    @media (max-width: $mobile-size) {
                        position: absolute;

                        top: 11px;
                        right: 34px;
                    }

                    @media (max-width: $mobile-min-size) {
                        top: 14px;
                        right: 28px;
                    }
                }

                .vpsymbol {
                    display: none;
                    @include font(14px, #000);
                    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);

                    @media (max-width: $mobile-size) {
                        display: block;
                        position: absolute;
                        top: 14px;
                        right: 10px;
                    }

                    @media (max-width: $mobile-min-size) {
                        font-size: 10px;
                        top: 16px;
                        right: 10px;
                    }
                }
            }

            .vkrtovp {
                position: absolute;
                top: 194px;
                right: 76px;
                opacity: 0.6;

                @include fontBold(16px, #24211b);
                text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);

                @media (max-width: $mobile-size) {
                    top: 277px;
                    right: 57px;
                }

                @media (max-width: $mobile-min-size) {
                    top: 194px;
                    right: 40px;
                    font-size: 12px;
                }
            }
        }

        .exchange {
            width: 100%;
        }

        .tx {
            width: 100%;
            margin: 25px auto 0 auto;
        }

        .alert {
            width: 100%;
            margin: 40px auto 0 auto;
        }

        .checkbox {
            width: 140px;
            height: 30px;
            // margin: 23px auto 0 auto;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            border-radius: 15px;
            border: solid 1px rgba(#fff, 0.3);
            @include font(14px, #fff);

            &:hover {
                border-radius: 15px;
                background-color: #ffe646;
                color: black;

                .box {
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../resources/vp-ico-no-on.svg");
                }
            }

            &.checked {
                border-radius: 15px;
                border: solid 1px rgba(#4056c5, 0.1);
                background-color: rgba(#4056c5, 0.3);
                color: #4056c5;
            }

            // &:hover {
            //     opacity: 0.8;
            //     cursor: pointer;
            // }

            .box {
                // width: 16px;
                width: 20px;
                height: 20px;
                // background: white;
                margin: 5px 0 0 20px;

                width: 20px;
                height: 20px;
                background-size: cover;
                background-image: url("../../resources/vp-ico-no.svg");

                // .boxcheckico {
                //     display: none;
                // }

                &.checked {
                    // background: #4056c5;
                    // background-size: cover;
                    // background-image: url("../../resources/vp-ico-checked.svg");

                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../resources/vp-ico-ok3.svg");

                    // .boxcheckico {
                    //     width: 16px;
                    //     height: 16px;
                    //     background-size: cover;
                    //     background-image: url("../../resources/vp-ico-checked.svg");
                    // }
                }
            }

            .boxcontent {
                margin: 0 0 0 4px;

                line-height: 31px;

                // &.checked {

                // }
            }
        }

        .createbutton {
            width: calc(100% - 60px);
            height: 48px;
            margin: 30px auto 40px auto;

            @media (max-width: $mobile-size) {
                width: calc(100% - 40px);
            }

            .button {
                width: 260px;
                height: 100%;
                margin: 0 auto;

                @media (max-width: $mobile-size) {
                    width: 100%;
                }
            }
        }
    }

    .myvp_container {
        display: flex;
        flex-direction: column;

        .myvp_amount {
            display: flex;
            flex-direction: column;

            .myvp_amount_title {
                margin: 30px 0 0 30px;

                @media (max-width: $mobile-size) {
                    margin: 20px 0 0 20px;
                }
            }

            .myvp_amount_card {
                width: 248px;
                height: 368px;
                margin: 0 0 18px 16px;
                background-size: cover;
                background-image: url("../../resources/vp-img-card-bronze.png");
                position: relative;

                @media (max-width: $mobile-size) {
                    margin: 0 auto 18px auto;
                }

                &.silver {
                    background-image: url("../../resources/vp-img-card.png");
                }

                &.gold {
                    background-image: url("../../resources/vp-img-card-gold.png");
                }

                &.black {
                    background-image: url("../../resources/vp-img-card-black.png");
                }

                .myvp_amount_card_balance {
                    position: absolute;
                    height: 24px;
                    top: 237px;
                    left: 29px;
                    display: flex;
                    flex-direction: row;

                    .dividemargin {
                        margin: 8px 0 0 0;
                    }
                }
            }
        }

        .myvp_dummy {
            height: 1px;
            width: calc(100% - 60px);
            opacity: 0.2;
            background-color: #fff;
            margin: 0 auto 20px auto;

            @media (max-width: $mobile-size) {
                width: calc(100% - 40px);
            }
        }

        .myvp_rate {
            display: flex;
            flex-direction: column;

            .myvp_rate_title {
                margin: 0 0 0 30px;

                @media (max-width: $mobile-size) {
                    margin: 0 0 0 20px;
                }
            }

            .myvp_rate_vkr {
                width: calc(100% - 60px);
                height: 40px;
                border-radius: 2px;
                background-color: rgba(#000, 0.1);
                margin: 4px auto 0 auto;
                display: flex;
                flex-direction: row;

                @media (max-width: $mobile-size) {
                    margin: 4px auto 20px auto;
                    position: relative;
                    width: calc(100% - 40px);
                }

                .vkrico {
                    width: 30px;
                    height: 30px;
                    background-size: cover;
                    background-image: url("../../resources/vp-vkr-logo.svg");
                    margin: 5px 0 0 5px;
                }

                .vkr_amount {
                    margin: 0 0 0 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .vkr_symbol {
                    margin: 15px 0 0 4px;
                }

                .vkr_equal {
                    margin: 10px 0 0 33px;
                    width: 20px;
                    height: 20px;
                    background-size: cover;
                    background-image: url("../../resources/ico-rate-copy.svg");

                    @media (max-width: $mobile-size) {
                        position: absolute;
                        top: 0;
                        left: calc(50% - 45px);
                    }
                }

                .vp_amount {
                    margin: 0 0 0 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media (max-width: $mobile-size) {
                        position: absolute;
                        top: 12px;
                        left: calc(50% + 20px);
                    }
                }

                .vp_symbol {
                    margin: 15px 0 0 4px;

                    @media (max-width: $mobile-size) {
                        position: absolute;
                        top: 0;
                        left: calc(50% + 38px);
                    }
                }
            }
        }
    }
}
