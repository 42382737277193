@import "mixins";
@import "variables";


.container {
    
    width: calc(40% - 20px);

    @media (max-width: $mobile-size) {
        width: 100%;
    }

    .total_container_box{
        @include box_shadow();
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);

        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-image: linear-gradient(284deg, #23293b, #39425d 0%);

        padding: 30px;

        @media (max-width: $mobile-size) {
            margin-bottom: 0px;
            padding: 20px;
        }

        .total_container {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            @media (max-width: $mobile-size) {
                flex-direction: column;
                align-items: flex-start;
            }

            .text {
                display: flex;
                flex-direction: column;

                .staked_wrap {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    margin-top: 6px;
                }
            }
            .button {
                width: 100px;
                height: 30px;
                align-self: flex-end;
            }

            
            
        }
    }

    .container_box{
        display: flex;
        flex-direction: column;

        border-radius: 2px;
        border: solid 1px rgba(255, 255, 255, 0.2);
        background-color: rgba(255, 255, 255, 0.05);

        padding: 30px;
        margin-top: -1px;
        border-top: transparent;

        @media (max-width: $mobile-size) {
            margin-bottom: 40px;
            padding: 20px;
        }

        .total_conatiner {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            .text {
                display: flex;
                flex-direction: column;

                .staked_wrap {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    margin-top: 6px;
                }
            }
            .button {
                width: 64px;
                height: 32px;
            }

            
            
        }

        .lp_container {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            .text {
                display: flex;
                flex-direction: column;

                .staked_wrap {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    margin-top: 6px;
                }
            }
            .button {
                width: 64px;
                height: 32px;
            }

            
            
        }
        .deposit_container {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            .text {
                display: flex;
                flex-direction: column;

                .deposit {
                    display: flex;
                    flex-direction: row;

                    .icon {
                        margin-bottom: 3px;
                        width: 20px;
                        height: 20px;
                        background-image: url(../../resources/ico-feelock01.svg);
                        object-fit: contain;
                        opacity: 0.5;
                    }
                }

                .staked_wrap {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    margin-top: 6px;
                }
            }
            .button {
                width: 64px;
                height: 32px;
            }
            
        }
        .vp_container {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            .text {
                display: flex;
                flex-direction: column;

                .staked_wrap {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    margin-top: 6px;
                }
            }
            .button {
                width: 64px;
                height: 32px;
            }
            
        }
        .line {
            width: 100%;
            height: 1px;
            background-color: rgba($color: #fff, $alpha: 0.2);
            margin-top: 12px;
            margin-bottom: 12px;
        }
    }
}