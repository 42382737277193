@import "mixins";
@import "variables";

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    .title_container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .body_container {
        border-radius: 10px;
        margin-top: 8px;

        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .field {
            @include font(14px, rgba(#fff, 0.3));

            border-radius: 2px;

            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 19px;
            padding-right: 99px;
            width: 10px;
            flex: 1;
            height: 50px;
            outline: none;
            -webkit-appearance: none;

            background-color: #282a3c;
            border: solid 1px rgba(255, 255, 255, 0.3);

            &:focus {
                border: solid 3px #ffe646;
                height: 46px;
                // width: 377px;
                padding-left: 17px;
                background: #fff;
                color: black;

                &::placeholder {
                    color: rgba(0, 0, 0, 0.3);
                }
            }

            &.error {
                height: 46px;
                padding-left: 17px;
                border: solid 3px #a02d46;
                color: #a02d46;
            }

            &.disabled {
                height: 50px;
                padding-left: 19px;
                border: 1px solid white;
                box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
                background-color: transparent;
                color: white;
            }

            &::placeholder {
                color: rgba(255, 255, 255, 0.3);
            }
        }

        &:focus-within {
            .pastebutton {
                border: solid 1px rgba(#000, 0.1);
                color: #2d2d2d;
            }
        }

        .pastebutton {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);

            height: 30px;
            border-radius: 15px;
            border: solid 1px rgba(white, 0.3);

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-left: 13px;
            padding-right: 13px;
            cursor: pointer;
            @include font(12px, white);

            &:hover {
                background-color: #ffe646;
                color: #2d2d2d;
            }
        }
    }

    .error_container {
        display: flex;
        flex-direction: row;
        height: 24px;
        margin-top: 6px;

        .error_text {
            @include fontLight(12px, #fd5214);
            word-break: break-all;
            padding-right: 12px;
            letter-spacing: -0.4px;
        }
    }
}
