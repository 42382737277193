@import "mixins";
@import "variables";

.container {
    // height: 210px;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile-size) {
        margin: 20px 0 0 0;
        // height: 290px;
    }

    .title {
        height: 24px;
        line-height: 24px;
    }

    .contentswrap {
        width: 100%;
        height: calc(100% + 300px);

        background-color: rgba(0, 0, 0, 0.3);
        position: relative;
        z-index: 2;
        border: solid 1px rgba(255, 255, 255, 0.3);
        border-radius: 2px;
        margin: 20px 0 0 0;

        @media (max-width: $mobile-size) {
            height: calc(100%);
        }

        .contentsbg_footer {
            top: 0;
            left: 0px;
            width: 100%;
            height: calc(100%);
            // footer pc + footer margin pc
            position: absolute;

            background-image: radial-gradient(
                circle at 0 100%,
                rgba(82, 65, 61, 0),
                rgba(#f29077, 0.5)
            );

            box-shadow: 0 20px 15px 0 rgba(0, 0, 0, 0.3);

            z-index: -1;
            @media (max-width: $mobile-size) {
                // footer mobile + footer margin mobile
            }
        }

        .contents {
            display: flex;
            flex-direction: column;

            .contents_amount {
                height: 93px;
                margin: 28px auto 0 auto;
                width: calc(100% - 60px);
                display: flex;
                flex-direction: row;

                @media (max-width: $mobile-size) {
                    height: 91px;
                    margin: 20px auto 0 auto;
                    width: calc(100% - 40px);
                }

                .contents_amount_text {
                    width: calc(100% - 220px);
                    display: flex;
                    flex-direction: column;

                    @media (max-width: $mobile-size) {
                        width: 100%;
                    }

                    .contents_amount_text_title {
                        height: 20px;
                        display: flex;
                        flex-direction: row;
                        line-height: 20px;

                        .totalreceive {
                            margin: 0 6px 0 0;
                        }

                        .totalreceive_amount {
                            cursor: pointer;
                        }
                    }

                    .contents_amount_text_sub {
                        height: 15px;
                        margin: 18px 0 0 0;
                        line-height: 15px;
                    }

                    .contents_amount_text_amount {
                        height: 34px;
                        margin: 4px 0 0 0;
                        display: flex;
                        flex-direction: row;

                        .text_margin {
                            margin: 15px 0 0 0;
                        }

                        .text_margin2 {
                            margin: 15px 0 0 4px;
                        }
                    }
                }

                .contents_amount_buttonfield {
                    width: 220px;

                    @media (max-width: $mobile-size) {
                        display: none;
                    }

                    .contents_amount_button {
                        width: 100%;
                        height: 48px;
                        border-radius: 26px;
                        background-color: rgba(#fff, 0.1);
                        margin: 42px 0 0 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        @include font(16px, rgba(255, 255, 255, 0.3));
                        line-height: 48px;

                        &:hover {
                            cursor: not-allowed;
                            width: calc(100% - 2px);
                            height: 46px;
                            line-height: 46px;
                            border-radius: 28.5px;
                            border: solid 1px rgba(#fff, 0.1);
                            background-color: rgba(#000, 0.1);
                        }

                        &.enabled {
                            border-radius: 26px;
                            border: solid 1px rgba(#fff, 0.3);
                            background: none;
                            @include font(16px, #ffe646);

                            &:hover {
                                background-color: #ffe646;
                                cursor: pointer;
                                color: black;
                                line-height: 48px;
                                width: 100%;
                            }
                        }

                        &.end {
                            width: 100%;
                            height: 48px;
                            border-radius: 26px;
                            background-color: rgba(#fff, 0.1);
                            margin: 42px 0 0 0;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            @include font(16px, rgba(255, 255, 255, 0.3));
                            line-height: 48px;

                            &:hover {
                                cursor: not-allowed;
                                width: calc(100% - 2px);
                                height: 46px;
                                line-height: 46px;
                                border-radius: 28.5px;
                                border: solid 1px rgba(#fff, 0.1);
                                background-color: rgba(#000, 0.1);
                            }
                        }
                    }
                }
            }

            .contents_bar {
                height: 49px;
                margin: 12px auto 30px auto;
                width: calc(100% - 60px);
                display: flex;
                flex-direction: column;

                @media (max-width: $mobile-size) {
                    width: calc(100% - 40px);
                }

                .contents_bar_date {
                    height: 20px;
                    display: flex;
                    flex-direction: row;

                    .contents_bar_date_text {
                        line-height: 20px;
                    }

                    .contents_bar_date_ico {
                        width: 20px;
                        height: 20px;
                        margin: -2px 0 0 0;
                        background-size: cover;
                        background-image: url("../../../../../../resources/ico-feelock-ipcreward.svg");
                    }
                }

                .contents_bar_progress {
                    height: 6px;
                    margin: 4px 0 0 0;
                    background: rgba(#fff, 0.1);
                    border-radius: 8px;
                    position: relative;

                    .progressbar_now {
                        position: absolute;
                        height: 6px;
                        top: 0;
                        left: 0;
                        background-color: #ffe646;
                        border-radius: 8px 0 0 8px;

                        &.full {
                            border-radius: 8px;
                        }

                        .progressbar_claimed {
                            position: absolute;
                            height: 6px;
                            top: 0;
                            left: 0;
                            background-color: rgb(169, 155, 150);
                            border-radius: 8px 0 0 8px;

                            &.full {
                                border-radius: 8px;
                            }
                        }
                    }

                    .progressbar_point {
                        position: absolute;
                        width: 1px;
                        height: 6px;
                        top: -6px;
                        left: 0;
                        background: white;
                    }
                }

                .contents_bar_amount {
                    height: 15px;
                    margin: 4px 0 0 0;
                    line-height: 15px;
                }
            }

            .myreward_dummy {
                display: none;

                @media (max-width: $mobile-size) {
                    display: block;
                    width: calc(100% - 40px);
                    height: 1px;
                    margin: 0 auto;
                    opacity: 0.2;
                    background-color: #fff;
                }
            }

            .mobile_button {
                display: none;

                @media (max-width: $mobile-size) {
                    width: calc(100% - 40px);
                    height: 48px;
                    border-radius: 26px;
                    background-color: rgba(#fff, 0.1);
                    margin: 20px auto 30px auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    line-height: 48px;
                    @include font(16px, rgba(255, 255, 255, 0.3));

                    &:hover {
                        cursor: not-allowed;
                        width: calc(100% - 42px);
                        height: 46px;
                        line-height: 46px;
                        border-radius: 28.5px;
                        border: solid 1px rgba(#fff, 0.1);
                        background-color: rgba(#000, 0.1);
                    }

                    &.enabled {
                        border-radius: 26px;
                        border: solid 1px rgba(#fff, 0.3);
                        background: none;
                        @include font(16px, #ffe646);

                        &:hover {
                            background-color: #ffe646;
                            cursor: pointer;
                            color: black;
                            line-height: 48px;
                            width: calc(100% - 40px);
                            height: 48px;
                        }
                    }

                    &.end {
                        width: calc(100% - 40px);
                        height: 48px;
                        border-radius: 26px;
                        background-color: rgba(#fff, 0.1);
                        margin: 20px auto 30px auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        line-height: 48px;
                        @include font(16px, rgba(255, 255, 255, 0.3));

                        &:hover {
                            cursor: not-allowed;
                            width: calc(100% - 42px);
                            height: 46px;
                            line-height: 46px;
                            border-radius: 28.5px;
                            border: solid 1px rgba(#fff, 0.1);
                            background-color: rgba(#000, 0.1);
                        }
                    }
                }
            }
        }
    }
}
